import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const HighCharts = (props) => {
  let subset = [];
  let final = [];

  for (let i in props.data) {
    subset.push(props.data[i]["date"]);
    subset.push(props.data[i]["final_price"]);
    final.push(subset);
    subset = [];
  }
  const options = {
    series: [
      {
        data: final,
        color: "#000080",
        id: "dataseries",
      },
    ],
    chart: {
      width: 1350,
    },
    credits: {
      enabled: false,
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          moment(new Date(this.x)).subtract(1, "dates").format("DD") +
          " " +
          moment(new Date(this.x)).format("MMM") +
          " " +
          moment(new Date(this.x)).format("YYYY") +
          ": </b>" +
          this.y
        );
      },
    },
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Prices (INR Quintals)",
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
