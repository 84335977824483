import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand, CardImg, NavLink } from "reactstrap";

export const InitialNavbar = () => {

  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token"))
  }, []);
  return (
    <div>
      <Navbar
        light
        expand="md"
        style={{
          backgroundColor: "#fff",
          borderColor: "#fff",
          height: "60px",
          paddingLeft: "15px",
          borderRadius: "20px",
          boxSizing: "border-box",
        }}
      >
        <NavbarBrand
          href={token ? "/?token=" + token : ""}
          style={{ color: "#4941A7" }}
        >
          Price Risk Management Tool
        </NavbarBrand>
        <NavLink
          className="ml-auto d-none d-sm-block"
          href="https://www.arya.ag/"
        >
          <CardImg
            style={{
              borderRadius: "20%",
              width: "55px",
              maxWidth: "55px",
              display: "block",
              objectFit: "cover",
            }}
            src="arya_logo.png"
            alt="Card image cap"
          />
        </NavLink>
        <NavLink className="d-none d-sm-block">
          <CardImg
            style={{
              borderRadius: "20%",
              width: "55px",
              maxWidth: "55px",
              maxHeight: "50px",
              height: "50px",
              display: "block",
              objectFit: "cover",
            }}
            src="rabo_logo.png"
            alt="Card image cap"
          />
        </NavLink>
        {/* <NavLink onClick={signOut}>
          <BiLogOutCircle size="23px" style={{ color: "#4941A7" }} />
        </NavLink> */}
      </Navbar>
    </div>
  );
};
