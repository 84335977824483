import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const ArrivalModalGraph = (props) => {
  let subset = [];
  let final2 = [];
  let subset2 = [];
  let final = [];
  if (props) {
    for (let i in props.data) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset2.push(date);
      subset2.push(props.data[i]["arrivals"]);
      final2.push(subset2);
      subset2 = [];
    }
    for (let j in props.data) {
      // subset.push(props.data[i]['month'])
      let date2 = new Date(props.data[j]["date"]);
      date2 = date2.setFullYear(2010);
      subset.push(date2);
      subset.push(props.data[j]["final_price"]);
      final.push(subset);
      subset = [];
    }
  }
  const options = {
    series: [
      {
        data: final,
        yAxis: 1,
        name: "Prices (INR Quintals)",
        color: "#ff9900",
      },
      {
        data: final2,
        type: "column",
        opacity: "0.7",
        color: "#0066ff",
        name: "Arrivals (q)",
      },
    ],
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "Average of <b>" +
          moment(new Date(this.x)).subtract(0, "months").format("MMM") +
          "</b>: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Prices (INR Quintals)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "Arrivals (q)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(new Date(this.value))
            .subtract(1, "months")
            .format("MMM");
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
