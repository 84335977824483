import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const FloorGraph = (props) => {
  let subset = [];
  let final = [];
  if (props) {
    for (let i in props.data) {
      subset.push(props.data[i]["dte"]);
      subset.push(props.data[i]["SMA_15"]);
      final.push(subset);
      subset = [];
    }
  }
  const options = {
    series: [
      {
        data: final,
        name: "SMA 15 (INR Quintals)",
        color: "#ff9900",
      },
    ],
    title: {
      text: "Floor Price Evaluation Graph",
    },
    subtitle: {
      text: "Drag to zoom",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          moment(new Date(this.x)).subtract(1, "dates").format("DD") +
          " " +
          moment(new Date(this.x)).format("MMM") +
          " " +
          moment(new Date(this.x)).format("YYYY") +
          "</b>: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "SMA 15 (INR Quintals)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
    ],
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(new Date(this.value))
            .subtract(0, "months")
            .format("MMM");
        },
      },
    },
    chart: {
      zoomType: "x",
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
