import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  function redirect() {
    window.location.href =
      "https://arya.ag/?returnUrl=rms.arya.ag" + path;
  }
  useEffect(() => {
    fetch("/api/isValidToken", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoggedIn(false);
        } else return response.json();
      })
      .then((data) => {
        setIsLoggedIn(true);
      });
  }, []);
  return (
    <>
      {isLoggedIn ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
      ) : (
        redirect()
      )}
    </>
  );
};
