import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  // NavLink as NavL,
} from "reactstrap";
import { NavLink } from "react-router-dom";

export const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [token, setToken] = useState("");
  useEffect(() => {
    setToken(localStorage.getItem("token"))
  }, []);

  return (
    <div>
      <Navbar
        light
        expand="lg"
        style={{
          backgroundColor: "#fff",
          borderColor: "#fff",
          paddingTop: "10px",
          paddingLeft: "15px",
          borderRadius: "20px",
          boxSizing: "border-box",
        }}
      >
        <NavbarBrand
          href={token ? "/?token=" + token : ""}
          style={{ color: "#4941A7" }}
        >
          Price Risk Management Tool
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="mr-2" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar style={{ paddingLeft: "10%" }}>
            <NavItem
              style={{
                paddingRight: "20px",
              }}
            >
              <NavLink
                to="/dashboard"
                style={{ color: "#4941A7", textDecoration: "none" }}
                activeStyle={{
                  color: "#000",
                  textDecoration: "underline",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Historical Price Analysis
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                paddingRight: "20px",
              }}
            >
              <NavLink
                to="/seasonAnalysis"
                style={{ color: "#4941A7", textDecoration: "none" }}
                activeStyle={{
                  color: "#000",
                  textDecoration: "underline",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Season Analysis
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                paddingRight: "20px",
              }}
            >
              <NavLink
                to="/overview"
                style={{ color: "#4941A7", textDecoration: "none" }}
                activeStyle={{
                  color: "#000",
                  textDecoration: "underline",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Commodity Indicators
              </NavLink>
            </NavItem>
          </Nav>
          {/* <NavL onClick={signOut}>
            <BiLogOutCircle size="23px" style={{ color: "#4941A7" }} />
          </NavL> */}
        </Collapse>
      </Navbar>
    </div>
  );
};
