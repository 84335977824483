import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const SeasonAnalysisGraph = (props) => {
  let subset = [];
  let final2 = [];
  let subset2 = [];
  let final = [];
  if (props) {
    for (let i in props.data) {
      subset2.push(props.data[i]["date"]);
      subset2.push(props.data[i]["arrivals"]);
      final2.push(subset2);
      subset2 = [];
    }
    for (let j in props.data) {
      subset.push(props.data[j]["date"]);
      subset.push(props.data[j]["final_price"]);
      final.push(subset);
      subset = [];
    }
  }
  const options = {
    series: [
      {
        data: final,
        yAxis: 1,
        name: "Prices (INR Quintals)",
        color: "#ff9900",
      },
      {
        data: final2,
        type: "column",
        opacity: "0.7",
        color: "#0066ff",
        name: "Arrivals (q)",
      },
    ],
    title: {
      text:
        "Spot Prices vs Arrivals " +
        (new Date().getFullYear() - 1) +
        "-" +
        new Date().getFullYear(),
    },
    subtitle: {
      text: "Drag to zoom",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          moment(new Date(this.x)).subtract(1, "dates").format("DD") +
          " " +
          moment(new Date(this.x)).format("MMM") +
          " " +
          moment(new Date(this.x)).format("YYYY") +
          "</b>: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Arrivals (q)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "Prices (INR Quintals)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(new Date(this.value))
            .subtract(0, "months")
            .format("MMM");
        },
      },
    },
    chart: {
      zoomType: "x",
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
