import fetchIntercept from "fetch-intercept";

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    // console.log(url, config);
    config.headers.token = config.headers["Authorization"].split(" ")[1];
    localStorage.setItem("token", config.headers.token);
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    // console.log(error);
    return Promise.reject(error);
  },
});

// // Call fetch to see your interceptors in action.
fetch("/api/isValidToken");

// // Unregister your interceptor
// unregister();
