import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import SandSignika from "highcharts/themes/sand-signika";

export const CropLimit = (props) => {
  SandSignika(Highcharts);
  const absDifference = (arr1, arr2) => {
    const res = [];
    for (let i = 0; i < arr1.length; i++) {
      const el = Math.abs((arr1[i] || 0) - (arr2[i] || 0));
      res[i] = el;
    }
    return res;
  };
  let unutilized = absDifference(props.limit, props.limitUtil);

  const options = {
    series: [
      {
        name: "Available Limit",
        data: unutilized,
      },
      {
        name: "Utilized Limit",
        data: props.limitUtil,
      },
    ],
    chart: {
      type: "column",
    },
    title: {
      text: "Crop Limits",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: props.crops,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Crop Utilization",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -10,
      verticalAlign: "top",
      y: 10,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter: function () {
            var val = this.y;
            if (val === 0) {
              return "";
            }
            return val;
          },
          style: {
            fontSize: "10px",
            textOutline: "0",
            fontWeight: "lighter",
          },
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
