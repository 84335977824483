import React, { useState, useEffect } from "react";
import {
  Card,
  CardFooter,
  CardTitle,
  Button,
  CardSubtitle,
  Label,
} from "reactstrap";
import { PortfolioNavbar } from "./PortfolioNavbar";
import MaterialTable from "material-table";
import data from "../../data/data.json";
import Select from "react-select";

// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
// import Auth from "@aws-amplify/auth";

export const PortfolioDashboard = () => {
  const [cropMaster, setCropMaster] = useState([]);
  // const [diversification, setDiversification] = useState([]);
  const [diversificationRabi, setDiversificationRabi] = useState([]);
  const [diversificationKharif, setDiversificationKharif] = useState([]);
  const [season, setSeason] = useState(null);

  const [crop, setCrop] = useState(null);
  const [marketList, setMarketList] = useState([]);
  const [market, setMarket] = useState(null);
  const [issuanceWindow, setIssuanceWindow] = useState([]);
  const [issuanceFrequency, setIssuanceFrequency] = useState([]);
  const [issuanceArrivals, setIssuanceArrivals] = useState([]);
  const [issuanceArrivalsFrequency, setIssuanceArrivalsFrequency] = useState(
    []
  );

  const handleConfirmButton = async (e) => {
    if (crop !== null && market !== null) {
      let token = localStorage.getItem("token");

      await fetch("/api/issuanceFrequency", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIssuanceFrequency(data);
        });
      await fetch("/api/issuanceArrivalsFrequency", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIssuanceArrivalsFrequency(data);
        });
      await fetch("/api/issuance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIssuanceWindow(data);
        });
      await fetch("/api/issuanceArrivals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIssuanceArrivals(data);
        });
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select the appropriate fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  const handleCommodityChange = (obj) => {
    setCrop(obj);
    setMarketList(obj.markets);
    setMarket(null);
  };
  const handleMarketChange = (obj) => {
    setMarket(obj);
  };
  let cols_issuance = [
    { field: "crop", title: "Crop", cellStyle: { padding: "5px" } },
    { field: "market", title: "Market" },
    { field: "week", title: "Week Number" },
    { field: "month", title: "Month" },
    {
      field: "2013",
      title: "2013",
    },
    { field: "2014", title: "2014" },
    { field: "2015", title: "2015" },
    { field: "2016", title: "2016" },
    { field: "2017", title: "2017" },
    { field: "2018", title: "2018" },
    { field: "2019", title: "2019" },
    { field: "2020", title: "2020" },
    { field: "2021", title: "2021" },
    { field: "2022", title: "2022" },
  ];

  let cols_diverse_kharif = [
    { field: "crop", title: "Crop", defaultGroupOrder: 0 },
    { field: "market", title: "Market", defaultGroupOrder: 0 },
    { field: "year", title: "Year", grouping: false, sorting: false },
    { field: "base", title: "Base", grouping: false, sorting: false },

    {
      field: "Nov%",
      title: "Nov%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Nov%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Nov%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Nov%"];
        }
      },
    },
    {
      field: "Dec%",
      title: "Dec%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Dec%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Dec%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Dec%"];
        }
      },
    },
    {
      field: "Jan%",
      title: "Jan%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jan%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jan%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jan%"];
        }
      },
    },
    {
      field: "Feb%",
      title: "Feb%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Feb%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Feb%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Feb%"];
        }
      },
    },
    {
      field: "Mar%",
      title: "Mar%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Mar%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Mar%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Mar%"];
        }
      },
    },
    {
      field: "Apr%",
      title: "Apr%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Apr%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Apr%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Apr%"];
        }
      },
    },
    {
      field: "May%",
      title: "May%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["May%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["May%"]}
              </span>
            </div>
          );
        } else {
          return rowData["May%"];
        }
      },
    },
    {
      field: "Jun%",
      title: "Jun%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jun%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jun%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jun%"];
        }
      },
    },
    {
      field: "Jul%",
      title: "Jul%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jul%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jul%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jul%"];
        }
      },
    },
    {
      field: "Aug%",
      title: "Aug%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Aug%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Aug%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Aug%"];
        }
      },
    },
    {
      field: "Sep%",
      title: "Sep%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Sep%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Sep%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Sep%"];
        }
      },
    },
    {
      field: "Oct%",
      title: "Oct%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Oct%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Oct%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Oct%"];
        }
      },
    },
  ];
  let cols_diverse_rabi = [
    { field: "crop", title: "Crop", defaultGroupOrder: 0 },
    { field: "market", title: "Market", defaultGroupOrder: 0 },
    { field: "year", title: "Year", grouping: false, defaultSort: "asc" },
    { field: "base", title: "Base", grouping: false, sorting: false },
    {
      field: "Jun%",
      title: "Jun%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jun%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jun%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jun%"];
        }
      },
    },
    {
      field: "Jul%",
      title: "Jul%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jul%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jul%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jul%"];
        }
      },
    },
    {
      field: "Aug%",
      title: "Aug%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Aug%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Aug%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Aug%"];
        }
      },
    },
    {
      field: "Sep%",
      title: "Sep%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Sep%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Sep%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Sep%"];
        }
      },
    },
    {
      field: "Oct%",
      title: "Oct%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Oct%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Oct%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Oct%"];
        }
      },
    },
    {
      field: "Nov%",
      title: "Nov%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Nov%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Nov%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Nov%"];
        }
      },
    },
    {
      field: "Dec%",
      title: "Dec%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Dec%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Dec%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Dec%"];
        }
      },
    },
    {
      field: "Jan%",
      title: "Jan%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Jan%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jan%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Jan%"];
        }
      },
    },
    {
      field: "Feb%",
      title: "Feb%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Feb%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Feb%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Feb%"];
        }
      },
    },
    {
      field: "Mar%",
      title: "Mar%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Mar%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Mar%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Mar%"];
        }
      },
    },
    {
      field: "Apr%",
      title: "Apr%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["Apr%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Apr%"]}
              </span>
            </div>
          );
        } else {
          return rowData["Apr%"];
        }
      },
    },
    {
      field: "May%",
      title: "May%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        let maxValue = -1;
        for (let prop in rowData) {
          if (
            prop === "tableData" ||
            prop === "index" ||
            prop === "base" ||
            prop === "crop" ||
            prop === "market"
          ) {
            continue;
          } else {
            let value = rowData[prop];

            if (value > maxValue) {
              maxValue = value;
            }
          }
        }
        if (rowData["May%"] === maxValue) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["May%"]}
              </span>
            </div>
          );
        } else {
          return rowData["May%"];
        }
      },
    },
  ];
  let cols = [
    { field: "crop", title: "Crop", editable: "never",grouping: false },
    { field: "state", title: "State", editable: "never",grouping: false },
    { field: "market", title: "Market", editable: "never", grouping: false },
    { field: "year", title: "Year", editable: "never",defaultGroupOrder: 0  },
    {
      field: "policy_issuance_start",
      title: "Arrival Start",
      editable: "never",
      grouping: false
    },
    { field: "policy_issuance_end", title: "Arrival End", editable: "never",grouping: false },

    { field: "lockin_period", title: "Lock-In", editable: "never",grouping: false },
    { field: "max_policy_tenure", title: "Max RMS Tenure",grouping: false },
    { field: "crop_limit", title: "Crop Limit", editable: "never" ,grouping: false},
    {
      field: "crop_limit_utilization",
      title: "Crop Utilization",
      editable: "never",
      grouping: false
    },
    { field: "premium_percentage", title: "RMS fee(%)",grouping: false },
  ];
  let final = [];
  let l = [];
  for (let j in issuanceFrequency) {
    l.push(issuanceFrequency[j]["week_number"]);
    final.push(issuanceFrequency[j]["value"]);
  }
  for (let j in issuanceArrivalsFrequency) {
    l.push(issuanceArrivalsFrequency[j]["week_number"]);
    final.push(issuanceArrivalsFrequency[j]["value"]);
  }
  useEffect(() => {
    setSeason("Kharif");
    let token = localStorage.getItem("token");

    fetch("/api/portfolioCropMaster", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCropMaster(data);
      });
  }, []);
  useEffect(() => {
    let token = localStorage.getItem("token");

    if (season === "Rabi") {
      fetch("/api/diversificationRabi", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDiversificationRabi(data);
        });
    } else if (season === "Kharif") {
      fetch("/api/diversificationKharif", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDiversificationKharif(data);
        });
    }
  }, [season]);
  const season_options = [
    {
      label: "Kharif",
      value: "Kharif",
    },
    {
      label: "Rabi",
      value: "Rabi",
    },
  ];
  const handleCellEdit = async (rowData) => {
    let token = localStorage.getItem("token");

    await fetch("/api/editRMS", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData.crop,
        state: rowData.state,
        rms_fee_percentage: rowData.premium_percentage,
        max_tenure: rowData.max_policy_tenure,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["message"] !== "Nothing to edit") {
          store.addNotification({
            title: "Agtuall",
            message: data["message"],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };
  return (
    <>
      <PortfolioNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-3 ml-3 mr-3 mb-3 pb-3"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <CardTitle tag="h4">Current Portfolio Overview</CardTitle>
            <MaterialTable
              data={cropMaster}
              columns={cols}
              options={{
                toolbar: true,
                showTitle: false,
                paging: false,
                exportButton: true,
                draggable: false,
                exportAllData: true,
                sorting: false,
                padding: "dense",
                grouping: true,
                headerStyle: {
                  backgroundColor: "#4941A7",
                  color: "#fff",
                  fontSize: 12,
                  height: "12px",
                  zIndex: "auto",
                },
                rowStyle: {
                  fontSize: 12,
                },
              }}
              cellEditable={{
                onCellEditApproved: (
                  newValue,
                  oldValue,
                  rowData,
                  columnDef
                ) => {
                  return new Promise((resolve, reject) => {
                    // console.log(
                    //   "newValue: " +
                    //     newValue +
                    //     rowData["premium_percentage"] +
                    //     oldValue +
                    //     columnDef
                    // );
                    if (oldValue === rowData["premium_percentage"]) {
                      rowData["premium_percentage"] = newValue;
                    } else if (oldValue === rowData["max_policy_tenure"]) {
                      rowData["max_policy_tenure"] = newValue;
                    }
                    handleCellEdit(rowData);
                    // alert("RMS Fee% Changed");
                    setTimeout(resolve, 1000);
                  });
                },
              }}
            />
          </Card>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Card
                body
                className="shadow mt-3 mb-3 ml-3 mr-3 pb-0"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  color: "#4941A7",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-md-3 mt-3">
                    <CardTitle tag="h4">Issuance Window Analysis</CardTitle>
                  </div>{" "}
                  <div
                    className="col-md-2 offset-md-4"
                    style={{ fontSize: "13px" }}
                  >
                    <Label>Crop Name</Label>
                    <Select
                      value={crop}
                      placeholder="Select Commodity"
                      options={data}
                      onChange={handleCommodityChange}
                      getOptionLabel={(x) => x.commodity_name}
                      getOptionValue={(x) => x.commodity_code}
                      menuPlacement="auto"
                    />
                  </div>
                  <div className="col-md-2" style={{ fontSize: "13px" }}>
                    <Label>Market Name</Label>
                    <Select
                      placeholder="Select Market Name"
                      value={market}
                      options={marketList}
                      onChange={handleMarketChange}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.code}
                      menuPlacement="auto"
                    />
                  </div>
                  <div className="col-md-1">
                    <Button
                      className="pl-4 pr-4 shadow mt-3"
                      style={{
                        backgroundColor: "#4941A7",
                        borderColor: "#4941A7",
                      }}
                      onClick={handleConfirmButton}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
                <hr />
                <div className="mt-2 mb-3">
                  {issuanceWindow.length > 0 && issuanceFrequency.length > 0 ? (
                    <>
                      <div className="col-md-12">
                        <Card
                          body
                          style={{
                            padding: "10px",
                            borderColor: "#4941a7",
                            color: "#000",
                            opacity: "0.9",
                          }}
                          className="mb-2 shadow-sm"
                        >
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                            className="text-left"
                          >
                            This table is used to evaluate the arrival and
                            arrival end for a commodity.
                          </p>
                        </Card>
                      </div>

                      <Accordion allowZeroExpanded allowMultipleExpanded>
                        <AccordionItem uuid="1">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="row justify-content-center">
                                <p
                                  style={{ fontWeight: "bold", fontSize: 18 }}
                                  className="ml-3 mt-2"
                                >
                                  Most Common Weeks with Lowest(3) Avg Prices:
                                </p>
                                <Label
                                  className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                  style={{
                                    backgroundColor: "#00995c",
                                    borderColor: "#00995c",
                                    color: "#fff",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {l
                                    ? "Week " + l[0] + ": " + final[0]
                                    : "none"}
                                </Label>
                                <Label
                                  className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                  style={{
                                    backgroundColor: "#9933ff",
                                    borderColor: "#9933ff",
                                    color: "#fff",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {l
                                    ? "Week " + l[1] + ": " + final[1]
                                    : "none"}
                                </Label>
                                <Label
                                  className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                  style={{
                                    backgroundColor: "#ff9933",
                                    borderColor: "#ff9933",
                                    color: "#fff",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {l
                                    ? "Week " + l[2] + ": " + final[2]
                                    : "none"}
                                </Label>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <MaterialTable
                              data={issuanceWindow}
                              columns={cols_issuance}
                              title="Issuance Window Analysis by Price"
                              options={{
                                toolbar: true,
                                paging: false,
                                sorting: false,
                                showTitle: true,
                                draggable: false,
                                exportButton: true,
                                exportAllData: true,
                                search: false,
                                padding: "dense",
                                headerStyle: {
                                  backgroundColor: "#4941A7",
                                  color: "#fff",
                                  fontSize: 12,
                                  height: "12px",
                                  zIndex: "auto",
                                },
                                rowStyle: {
                                  fontSize: 12,
                                },
                              }}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                        {issuanceArrivalsFrequency.length > 0 &&
                        issuanceArrivals.length > 0 ? (
                          <AccordionItem uuid="2">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="row justify-content-center">
                                  <p
                                    style={{ fontWeight: "bold", fontSize: 18 }}
                                    className="ml-3"
                                  >
                                    Most Common Weeks with Highest(3) Avg
                                    Arrivals:
                                  </p>
                                  <Label
                                    className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                    style={{
                                      backgroundColor: "#00995c",
                                      borderColor: "#00995c",
                                      color: "#fff",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {l
                                      ? "Week " + l[3] + ": " + final[3]
                                      : "none"}
                                  </Label>
                                  <Label
                                    className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                    style={{
                                      backgroundColor: "#9933ff",
                                      borderColor: "#9933ff",
                                      color: "#fff",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {l
                                      ? "Week " + l[4] + ": " + final[4]
                                      : "none"}
                                  </Label>
                                  <Label
                                    className="pt-2 pl-4 pr-4 pb-2 ml-2 shadow"
                                    style={{
                                      backgroundColor: "#ff9933",
                                      borderColor: "#ff9933",
                                      color: "#fff",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {l
                                      ? "Week " + l[5] + ": " + final[5]
                                      : "none"}
                                  </Label>
                                </div>{" "}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <MaterialTable
                                data={issuanceArrivals}
                                columns={cols_issuance}
                                title="Issuance Window Analysis by Arrivals"
                                options={{
                                  toolbar: true,
                                  paging: false,
                                  sorting: false,
                                  showTitle: true,
                                  draggable: false,
                                  exportButton: true,
                                  exportAllData: true,
                                  search: false,
                                  padding: "dense",
                                  headerStyle: {
                                    backgroundColor: "#4941A7",
                                    color: "#fff",
                                    fontSize: 12,
                                    height: "12px",
                                    zIndex: "auto",
                                  },
                                  rowStyle: {
                                    fontSize: 12,
                                  },
                                }}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                        ) : (
                          ""
                        )}
                      </Accordion>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Card>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Card
                body
                className="shadow mt-3 mb-3 ml-3 mr-3 pb-0"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  color: "#4941A7",
                }}
              >
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <CardTitle tag="h4">Max Tenure Analysis</CardTitle>
                    <CardSubtitle tag="h6">
                      % Change of Avg Monthly Prices over Harvest Month Avg
                      Price
                    </CardSubtitle>
                  </div>
                  <div
                    className="col-md-2 offset-md-1"
                    style={{ fontSize: "13px" }}
                  >
                    <Label className="mb-0">Select Season</Label>

                    <Select
                      className="mt-1"
                      placeholder="Select Season"
                      options={season_options}
                      onChange={(obj) => setSeason(obj["value"])}
                      value={{
                        value: season !== null ? season : "",
                        label: season !== null ? season : "",
                      }}
                      menuPlacement="auto"
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <Card
                    body
                    style={{
                      padding: "10px",
                      borderColor: "#4941a7",
                      color: "#000",
                      opacity: "0.9",
                    }}
                    className="mb-2 mt-2 shadow-sm"
                  >
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      className="text-left"
                    >
                      This table is used to evaluate the maximum coverage period
                      of RMS.
                    </p>
                  </Card>
                </div>

                {diversificationKharif.length !== 0 && season === "Kharif" ? (
                  <>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <MaterialTable
                          data={diversificationKharif}
                          columns={cols_diverse_kharif}
                          options={{
                            toolbar: false,
                            showTitle: false,
                            paging: false,
                            draggable: false,
                            sorting: true,
                            padding: "dense",
                            grouping: true,
                            maxBodyHeight: "300px",

                            headerStyle: {
                              backgroundColor: "#4941A7",
                              color: "#fff",
                              fontSize: 12,
                              height: "12px",
                              zIndex: "auto",
                            },
                            rowStyle: {
                              fontSize: 13,
                            },
                          }}
                        />{" "}
                        <br />
                        <CardSubtitle tag="h6">
                          Kharif Crops Harvest Month: October
                        </CardSubtitle>
                      </div>
                    </div>
                  </>
                ) : diversificationRabi.length !== 0 && season === "Rabi" ? (
                  <>
                    <MaterialTable
                      data={diversificationRabi}
                      columns={cols_diverse_rabi}
                      options={{
                        toolbar: false,
                        showTitle: false,
                        draggable: false,
                        paging: false,
                        sorting: true,
                        padding: "dense",
                        grouping: true,
                        maxBodyHeight: "300px",

                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: 12,
                          height: "12px",
                          zIndex: "auto",
                        },
                        rowStyle: {
                          fontSize: 13,
                        },
                      }}
                    />
                    <br />
                    <CardSubtitle tag="h6" className="mb-2">
                      Rabi Crops Harvest Months: April, May <br />
                    </CardSubtitle>
                  </>
                ) : (
                  <h6>Loading Data..</h6>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="row">
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
