import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const Monthly = (props) => {
  let curr_year = new Date().getFullYear();
  let subset = [];

  let year1 = [];
  let year2 = [];
  let year3 = [];
  let year4 = [];
  let year5 = [];
  let avg = [];
  function epoch(date) {
    return Date.parse(date);
  }
  for (let i in props.data) {
    if (props.data[i]["year"] === curr_year - 5) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset.push(date);
      subset.push(props.data[i]["final_price"]);
      year1.push(subset);
      subset = [];
    } else if (props.data[i]["year"] === curr_year - 4) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset.push(date);
      subset.push(props.data[i]["final_price"]);

      year2.push(subset);
      subset = [];
    } else if (props.data[i]["year"] === curr_year - 3) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset.push(date);
      subset.push(props.data[i]["final_price"]);

      year3.push(subset);
      subset = [];
    } else if (props.data[i]["year"] === curr_year - 2) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset.push(date);
      subset.push(props.data[i]["final_price"]);

      year4.push(subset);
      subset = [];
    } else if (props.data[i]["year"] === curr_year - 1) {
      let date = new Date(props.data[i]["date"]);
      date = date.setFullYear(2010);
      subset.push(date);
      subset.push(props.data[i]["final_price"]);

      year5.push(subset);
      subset = [];
    }
  }
  for (let j in props.tableData) {
    let date = new Date();
    date.setFullYear(2010, parseInt(j), 28);
    subset.push(epoch(date));
    subset.push(props.tableData[j]["Avg"]);
    avg.push(subset);
    subset = [];
  }
  const options = {
    series: [
      {
        data: year1,
        name: curr_year - 5,
        color: "#c61aff",
      },
      {
        data: year2,
        name: curr_year - 4,
        color: "#2eb82e",
      },

      {
        data: year3,
        name: curr_year - 3,
        color: "#ff9900",
      },
      {
        data: year4,
        name: curr_year - 2,
        color: "#e60000",
      },
      {
        data: year5,
        name: curr_year - 1,
        color: "#0066ff",
      },
      {
        data: avg,
        name: "Average",
        color: "#000",
      },
    ],
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Prices (INR Quintals)",
        rotation: 270,
        margin: 7,
      },
      opposite: true,
    },
    tooltip: {
      formatter: function () {
        return (
          moment(new Date(this.x)).subtract(0, "months").format("MMM") +
          " " +
          this.series.name +
          "</b> : <b>" +
          this.y +
          "</b>"
        );
      },
    },
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(new Date(this.value))
            .subtract(1, "months")
            .format("MMM");
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
