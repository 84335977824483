import React from "react";
import { Card, CardTitle, CardSubtitle, CardFooter } from "reactstrap";
import { SortTable } from "./SortTable";
import { PolicyNavbar } from "./PolicyNavbar";

export const AllPolicies = (props) => {
  return (
    <>
      <PolicyNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-2 ml-2 mr-2"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              borderRadius: "10px",
              color: "#4941A7",
            }}
          >
            <CardTitle tag="h6" className="lead">
              RMS
            </CardTitle>
            <CardSubtitle tag="h6" className="mb-0 small">
              Overview of all the RMS sold to different FPOs
            </CardSubtitle>
            <SortTable />
          </Card>
        </div>
      </div>

      {/* Footer */}
      <div className="row" style={{ backgroundColor: "#EFEFEF" }}>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
