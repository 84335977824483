const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:f0229007-fba1-4142-93fc-59f397163e45",
  aws_cognito_region: "ap-south-1",
  // prod user pool
  aws_user_pools_id: 'ap-south-1_zD7Killv9',
  aws_user_pools_web_client_id: '2r6io417r7qorduivs453mnng8',
  oauth: {},
  aws_mobile_analytics_app_region: "ap-south-1",
  aws_mobile_analytics_app_id: "5ad62968e226429d8f4c053d91a77f30",
};
export default awsmobile;
