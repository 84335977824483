import React, { useState, useEffect } from "react";
import JsonTable from "ts-react-json-table";
import { Modal, ModalHeader, ModalBody, Label, Button } from "reactstrap";
import { HiOutlineClock } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MaterialTable from "material-table";
import Auth from "@aws-amplify/auth";
// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
export const SortTable = (props) => {
  let [floorPrice, setFloorPrice] = useState(null);

  let [modalStatusColor, setModalStatusColor] = useState("");

  let [policies, setPolicies] = useState([]);
  let [policyDetails, setPolicyDetails] = useState([]);
  let [lastPrices, setLastPrices] = useState([]);
  let [lockinPeriod, setLockinPeriod] = useState(null);
  let [maxTenure, setMaxTenure] = useState(null);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    let token = localStorage.getItem("token");

    fetch("/api/policyDb", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== "0") {
          setPolicies(data);
        } else {
          console.log(data);
        }
      });
  }, []);

  let col2 = [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "final_price",
      label: "Price",
      cell: function (item, columnKey) {
        if (today <= maxTenure && today >= lockinPeriod) {
          if (item["final_price"] > floorPrice) {
            return (
              <span
                style={{
                  color: "green",
                }}
              >
                {item["final_price"]}
              </span>
            );
          } else {
            return (
              <span
                style={{
                  color: "red",
                }}
              >
                {item["final_price"]}
              </span>
            );
          }
        } else {
          return <span>{item["final_price"]}</span>;
        }
      },
    },
  ];

  // View Details modal toggle
  let [viewDetailsModal, setViewDetailsModal] = useState(false);
  let viewDetailsToggle = () => setViewDetailsModal(!viewDetailsModal);
  let closeBtn = (
    <button className="close" onClick={viewDetailsToggle}>
      &times;
    </button>
  );

  const handleDownloadRow = async (rowData) => {
    let token = localStorage.getItem("token");

    let table_content = [];

    await fetch("/api/policyDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        rms_id: rowData["rms_id"],
        fpo_name: rowData["fpo_name"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        table_content.push(data[0]["issuance_quantity"]);
        table_content.push(data[0]["service_charge"]);
        table_content.push(data[0]["rms_value"]);
        table_content.push(data[0]["storage_receipt"]);
      });
    await fetch("/api/getMaxTenure", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData["crop"],
        state: rowData["state"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        table_content.push(data["lockin_period"]);
        table_content.push(data["max_tenure"]);
        table_content.push(data["max_settlement"]);
      });

    const doc = new jsPDF();

    doc.autoTable({
      theme: "grid",
      head: [["Heading", "Description"]],
      body: [
        ["Risk Management Service ID", rowData["rms_id"]],
        ["Storage Receipt No.", table_content[3]],
        ["FPO Name", rowData["fpo_name"]],
        ["Maximum limit for Risk Management Service", "Rs 30,00,000"],
        ["Issuance Quantity", table_content[0] + " quintals"],
        [
          "Risk Management Service Value",
          "Rs " + table_content[2].toLocaleString("en-IN"),
        ],
        ["Initiation Date of Risk Management Service", table_content[4]],
        ["End Date of Risk Management Service", table_content[5]],
        ["Evaluation Period", table_content[4] + " to " + table_content[5]],
        ["Floor Price", "Rs " + rowData["floor_price"]],
        ["Max Settlement Price", "Rs " + table_content[6]],

        ["Crop", rowData["crop"]],
        ["Reference price", "NCDEX Spot Price"],
        [
          "Service Charge(without tax)",
          "Rs " + table_content[1].toLocaleString("en-IN"),
        ],
        ["Institution financing SR", "Aryadhan Financial Solutions"],
      ],
    });
    doc.save(rowData["rms_id"] + ".pdf");
  };

  const handleRowClick2 = async (rowData) => {
    let token = localStorage.getItem("token");

    await fetch("/api/policyDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        rms_id: rowData["rms_id"],
        fpo_name: rowData["fpo_name"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPolicyDetails(data);
        setFloorPrice(data[0]["floor_price"]);
        if (data[0]["status"] === "Live") {
          setModalStatusColor("green");
        } else if (data[0]["status"] === "Closed") {
          setModalStatusColor("yellow");
        } else if (data[0]["status"] === "Claim Ready") {
          setModalStatusColor("red");
        }
      });
    await fetch("/api/lastPrices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData["crop"],
        state: rowData["state"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLastPrices(data);
      });

    await fetch("/api/getMaxTenure", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData["crop"],
        state: rowData["state"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLockinPeriod(data["lockin_period"]);
        setMaxTenure(data["max_tenure"]);
      });

    setViewDetailsModal(true);
  };
  const handleCellEdit = async (rowData) => {
    let token = localStorage.getItem("token");

    await fetch("/api/editCoverageQty", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        rms_id: rowData.rms_id,
        closing_quantity: rowData.closing_quantity,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["message"] !== "Nothing to edit") {
          store.addNotification({
            title: "Agtuall",
            message: data["message"],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };
  return (
    <>
      <Modal
        isOpen={viewDetailsModal}
        toggle={viewDetailsToggle}
        className="modal-lg"
        style={{ maxWidth: "550px", width: "100%" }}
      >
        <ModalHeader
          close={closeBtn}
          toggle={viewDetailsToggle}
          style={{ color: "#000033" }}
        >
          RMS Details <br />
          <div className="row small">
            <div className="col-md-6">
              {" "}
              <HiOutlineClock size={17} color="green" className="mb-1 mr-1" />
              {viewDetailsModal === true
                ? policyDetails[0]["start_date"]
                : "none"}
              <br />
              <HiOutlineClock size={17} color="red" className="mb-1 mr-1" />
              {viewDetailsModal === true
                ? policyDetails[0]["end_date"]
                : "none"}
            </div>
            <div className="col-md-6">
              <Label
                className="pt-2 pl-4 pr-4 pb-2 mr-1 small shadow"
                style={{
                  backgroundColor: modalStatusColor,
                  opacity: "0.8",
                  fontWeight: "bold",
                  color: "#000",
                  borderRadius: "20px",
                  marginLeft: "200px",
                }}
              >
                {viewDetailsModal === true &&
                policyDetails[0]["status"] === "Closed"
                  ? "Closed"
                  : ""}
                {viewDetailsModal === true &&
                policyDetails[0]["status"] === "Live"
                  ? "Live"
                  : ""}
                {viewDetailsModal === true &&
                policyDetails[0]["status"] === "Claim Ready"
                  ? "Claim Ready"
                  : ""}
              </Label>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-6 mt-4">
              <div className="row">
                <div className="col-md-12">
                  {" "}
                  {/* FPO Name */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-2"
                    style={{
                      backgroundColor: "#0D0562",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    {viewDetailsModal === true
                      ? policyDetails[0]["fpo_name"]
                      : "none"}
                  </Label>
                </div>
              </div>

              <div className="row justify-content-center">
                <div
                  className="col-md-6 mt-2"
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                >
                  {/* Commodity Name */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-1"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    {viewDetailsModal === true
                      ? policyDetails[0]["crop"]
                      : "none"}
                  </Label>

                  {/* Floor Price */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-1"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    Floor Price: <BiRupee size={20} />
                    {viewDetailsModal === true
                      ? policyDetails[0]["floor_price"]
                      : "none"}
                  </Label>

                  {/* Final Settlement Price */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-1"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    Final SP: <BiRupee size={20} />
                    {viewDetailsModal === true
                      ? policyDetails[0]["final_settlement_price"]
                      : "none"}
                  </Label>
                </div>
                <div
                  className="col-md-6 mt-2"
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                >
                  {/* Coverage Quantity */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-2 ml-2"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    {viewDetailsModal === true
                      ? policyDetails[0]["issuance_quantity"] + " quintal"
                      : "none"}
                  </Label>

                  {/* Premium Price */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-1 shadow"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    Service: <BiRupee size={20} />
                    {viewDetailsModal === true
                      ? policyDetails[0]["service_charge"]
                      : "none"}
                  </Label>

                  {/* Payout */}
                  <Label
                    className="pt-2 pl-4 pr-4 pb-2 mr-1 ml-1"
                    style={{
                      backgroundColor: "#4941A7",
                      opacity: "90%",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    Payout: <BiRupee size={20} />
                    {viewDetailsModal === true
                      ? policyDetails[0]["payout"]
                      : "none"}
                  </Label>
                </div>
              </div>
              <hr />
              <div className="row justify-content-center">
                <div className="col-md-12">
                  {" "}
                  <CSVLink data={lastPrices}>
                    <Button
                      style={{
                        backgroundColor: "#ff6600",
                        borderColor: "#ff6600",
                      }}
                      type="submit"
                      className="shadow"
                    >
                      Download Price CSV
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-center d-none d-sm-block">
              <p
                style={{
                  color: "#8E94A7",
                }}
              >
                NCDEX Price for last 10 days.
              </p>
              <JsonTable
                columns={col2}
                className="table table-sm table-bordered m-auto small"
                rows={lastPrices}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className="row mt-4">
        <div className="col-md-12 mb-5 mt-4 pl-0 pr-3">
          {policies ? (
            <>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "RMS ID",
                    field: "rms_id",
                    editable: "never",
                  },
                  { title: "FPO Name", field: "fpo_name", editable: "never" },
                  { title: "Crop", field: "crop", editable: "never" },
                  { title: "Market", field: "market", editable: "never" },
                  {
                    title: "RMS Start Date",
                    field: "start_date",
                    editable: "never",
                  },
                  {
                    title: "Floor Price",
                    field: "floor_price",
                    editable: "never",
                  },
                  {
                    title: "Coverage Qty",
                    field: "closing_quantity",
                    width: "15%",
                  },
                  {
                    field: "status",
                    title: "Status",
                    editable: "never",
                    render: (rowData) => {
                      if (rowData.status === "Live") {
                        return (
                          <span
                            className="colored-circle"
                            style={{
                              backgroundColor: "#00FF00",
                              display: "inline-block",
                              marginLeft: "5px",
                              marginRight: "5px",
                              marginBottom: "-2px",
                              borderRadius: "100%",
                              borderStyle: "solid",
                              borderWidth: "0.5px",
                              borderColor: "#00FF00",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        );
                      } else if (rowData.status === "Closed") {
                        return (
                          <span
                            className="colored-circle"
                            style={{
                              backgroundColor: "#ffff00",
                              display: "inline-block",
                              marginLeft: "5px",
                              marginRight: "5px",
                              marginBottom: "-2px",
                              borderRadius: "100%",
                              borderStyle: "solid",
                              borderWidth: "0.5px",
                              borderColor: "#ffff00",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        );
                      } else if (rowData.status === "Claim Ready") {
                        return (
                          <span
                            className="colored-circle"
                            style={{
                              backgroundColor: "#ff0000",
                              display: "inline-block",
                              marginLeft: "5px",
                              marginRight: "5px",
                              marginBottom: "-2px",
                              borderRadius: "100%",
                              borderStyle: "solid",
                              borderWidth: "0.5px",
                              borderColor: "#ff0000",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        );
                      }
                    },
                  },
                ]}
                data={policies ? policies : "none"}
                actions={[
                  {
                    icon: "download",
                    tooltip: "Download RMS Details",
                    onClick: (event, rowData) => {
                      handleDownloadRow(rowData);
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  padding: "dense",
                  draggable: false,
                  sorting: false,

                  rowStyle: {
                    fontSize: "12px",
                  },
                  headerStyle: {
                    backgroundColor: "#4941A7",
                    color: "#fff",
                    fontSize: "12px",
                  },
                }}
                onRowClick={(evt, rowData) => {
                  handleRowClick2(rowData);
                }}
                cellEditable={{
                  onCellEditApproved: (
                    newValue,
                    oldValue,
                    rowData,
                    columnDef
                  ) => {
                    return new Promise((resolve, reject) => {
                      if (newValue < rowData["closing_quantity"]) {
                        rowData["closing_quantity"] = newValue;
                        handleCellEdit(rowData);
                        setTimeout(resolve, 1000);
                      } else {
                        store.addNotification({
                          title: "Agtuall",
                          message:
                            "Closing quantity should be less than current closing quantity",
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                        setTimeout(resolve, 1000);
                      }
                    });
                  },
                }}
              />
            </>
          ) : (
            <>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                No rms present yet. Head to 'New RMS' to issue one.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
