import "./App.css";
import React, { useState, useEffect } from "react";
import { TryButton } from "./components/CommodityAnalysis/TryButton";
import { About } from "./components/Portfolio/About";
import { AllPolicies } from "./components/PolicyManagement/AllPolicies";
import { MainLanding } from "./components/MainLanding";
import { PolicyDashboard } from "./components/PolicyManagement/PolicyDashboard";
import { Route, Switch } from "react-router-dom";
import { NewPolicyOnboarding } from "./components/PolicyManagement/NewPolicyOnboarding";
import { SortTable } from "./components/PolicyManagement/SortTable";
import { PortfolioDashboard } from "./components/Portfolio/PortfolioDashboard";
import ReactNotifications from "react-notifications-component";

import "@aws-amplify/ui/dist/style.css";
import { SeasonAnalysis } from "./components/CommodityAnalysis/SeasonAnalysis";
import { Simulation } from "./components/Portfolio/Simulation";
import { Diversification } from "./components/Portfolio/Diversification";
import { Reports } from "./components/Portfolio/Reports";
import { Overview } from "./components/CommodityAnalysis/Overview";
import { RMSInformation } from "./components/RMSInformation/RMSInformation";
import { unregister } from "./Interceptor";
import { PrivateRoute } from "./components/PrivateRoute";
const App = () => {
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      await fetch("/api/isValidToken", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            console.log(window.location.href);
            // window.location.href = "https://www.arya.ag"
          } else return response.json();
        })
        .then((data) => setUserRole(data["user"].split("/")[1]));
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  let routes;
  if (userRole === "sales") {
    routes = [
      <PrivateRoute path="/dashboard" key="/dashboard" component={TryButton} />,
      <PrivateRoute
        path="/seasonAnalysis"
        key="/seasonAnalysis"
        component={SeasonAnalysis}
      />,
      <PrivateRoute path="/overview" key="/overview" component={Overview} />,
      <PrivateRoute
        path="/rmsDashboard"
        key="/rmsDashboard"
        component={PolicyDashboard}
      />,
      <PrivateRoute path="/allRMS" key="/allRMS" component={AllPolicies} />,
      <PrivateRoute
        path="/newRMS"
        key="/newRMS"
        component={NewPolicyOnboarding}
      />,
    ];
  } else if (userRole === "fpo") {
    routes = [
      <PrivateRoute path="/dashboard" key="/dashboard" component={TryButton} />,
      <PrivateRoute
        path="/seasonAnalysis"
        key="/seasonAnalysis"
        component={SeasonAnalysis}
      />,
      <PrivateRoute path="/overview" key="/overview" component={Overview} />,
      <PrivateRoute
        path="/rmsInformation"
        key="/rmsInformation"
        component={RMSInformation}
      />,
    ];
  } else {
    routes = [
      <PrivateRoute
        path="/dashboard"
        key="/dashboard"
        component={TryButton}
        exact
      />,
      <PrivateRoute path="/about" key="/about" component={About} />,
      <PrivateRoute
        path="/rmsDashboard"
        key="/rmsDashboard"
        component={PolicyDashboard}
      />,
      <PrivateRoute path="/allRMS" key="/allRMS" component={AllPolicies} />,
      <PrivateRoute
        path="/newRMS"
        key="/newRMS"
        component={NewPolicyOnboarding}
      />,
      <PrivateRoute path="/sortTable" key="/sortTable" component={SortTable} />,
      <PrivateRoute
        path="/portfolioDashboard"
        key="/portfolioDashboard"
        component={PortfolioDashboard}
      />,
      <PrivateRoute
        path="/seasonAnalysis"
        key="/seasonAnalysis"
        component={SeasonAnalysis}
      />,
      <PrivateRoute
        path="/simulation"
        key="/simulation"
        component={Simulation}
      />,
      <PrivateRoute path="/overview" key="/overview" component={Overview} />,
      <PrivateRoute
        path="/diversification"
        key="/diversification"
        component={Diversification}
      />,
      <PrivateRoute path="/reports" key="/reports" component={Reports} />,
    ];
  }

  return (
    <div className="App">
      <main>
        <ReactNotifications />

        <Switch>
          {routes}

          <Route path="/" component={MainLanding} />
        </Switch>
      </main>
    </div>
  );
};

export default App;
