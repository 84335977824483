import React, { useState } from "react";
import { PortfolioNavbar } from "./PortfolioNavbar";
import {
  CardFooter,
  Card,
  CardTitle,
  Button,
  Spinner,
  CardSubtitle,
} from "reactstrap";
import MaterialTable from "material-table";
// import Auth from "@aws-amplify/auth";

export const Diversification = () => {
  const [diversification, setDiversification] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleDiversification = async (e, obj) => {
    let token = localStorage.getItem("token");

    setLoading(false);

    await fetch("/api/diversification", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== "0") {
          setDiversification(data);
        }
      });
    setLoading(true);
  };
  let cols = [
    { field: "crop", title: "Crop", grouping: false },
    { field: "market", title: "Market", grouping: false },
    { field: "season", title: "Season", grouping: false },
    { field: "index", title: "Year", defaultGroupOrder: 0 },
    { field: "base", title: "Base", grouping: false, sorting: false },
    {
      field: "Jun%",
      title: "Jun%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Jun%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jun%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jun%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Jul%",
      title: "Jul%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Jul%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jul%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jul%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Aug%",
      title: "Aug%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Aug%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Aug%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Aug%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Sep%",
      title: "Sep%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Sep%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Sep%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Sep%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Oct%",
      title: "Oct%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Oct%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Oct%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Oct%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Nov%",
      title: "Nov%",
      grouping: false,
      sorting: false,

      render: (rowData) => {
        if (rowData["Nov%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Nov%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Nov%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Dec%",
      title: "Dec%",
      grouping: false,
      sorting: false,
      render: (rowData) => {
        if (rowData["Dec%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Dec%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Dec%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Jan%",
      title: "Jan%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        if (rowData["Jan%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jan%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Jan%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Feb%",
      title: "Feb%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        if (rowData["Feb%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Feb%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Feb%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Mar%",
      title: "Mar%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        if (rowData["Mar%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Mar%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Mar%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "Apr%",
      title: "Apr%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        if (rowData["Apr%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Apr%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["Apr%"]}
              </span>
            </div>
          );
        }
      },
    },
    {
      field: "May%",
      title: "May%",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        if (rowData["May%"] > 0) {
          return (
            <div style={{ backgroundColor: "#66ff66" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["May%"]}
              </span>
            </div>
          );
        } else {
          return (
            <div style={{ backgroundColor: "#ff9999" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {rowData["May%"]}
              </span>
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      <PortfolioNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-3 mb-3 ml-3 mr-3 pb-0"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <CardTitle tag="h4">Crop Diversification</CardTitle>
            <CardSubtitle>
              % Change of Avg Monthly Prices over Harvest Month Avg Price
            </CardSubtitle>
            <hr />
            <div className="row justify-content-center">
              <div className="col-md-4">
                <Button
                  className="mb-3 shadow"
                  style={{
                    backgroundColor: "#4941A7",
                    borderColor: "#4941A7",
                  }}
                  type="submit"
                  onClick={handleDiversification}
                >
                  Diversification
                </Button>
              </div>
            </div>
            {loading ? (
              diversification.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <MaterialTable
                        data={diversification}
                        columns={cols}
                        options={{
                          toolbar: false,
                          showTitle: false,
                          paging: false,
                          sorting: true,
                          padding: "dense",
                          grouping: true,
                          maxBodyHeight: "300px",
                          headerStyle: {
                            backgroundColor: "#4941A7",
                            color: "#fff",
                            fontSize: 12,
                            height: "12px",
                            zIndex: "auto",
                          },
                          rowStyle: {
                            fontSize: 13,
                          },
                        }}
                      />{" "}
                      <br />
                      <CardSubtitle tag="h6">
                        Rabi Crops Harvest Months: April, May | Look at months
                        Jun-Dec <br />
                        Khariff Crops Harvest Month: October | Look at months
                        Jun-May
                      </CardSubtitle>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            ) : (
              <div className="text-center mb-2 mt-3">
                <Spinner color="dark" />
              </div>
            )}
          </Card>
        </div>
      </div>
      {/* Footer */}
      <div className="row">
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
