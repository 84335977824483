import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardFooter,
  CardSubtitle,
  Jumbotron,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
import { FaLeaf, FaRupeeSign } from "react-icons/fa";
import { PolicyNavbar } from "./PolicyNavbar";
import { BiRupee } from "react-icons/bi";
import { CropLimit } from "./CropLimit";
// import Auth from "@aws-amplify/auth";

export const PolicyDashboard = (props) => {
  const [totalPolicies, setTotalPolicies] = useState(null);
  const [totalFPOS, setTotalFPOS] = useState(null);
  const [sumInsured, setSumInsured] = useState(null);
  const [totalPayout, setTotalPayout] = useState(null);
  let [season, setSeason] = useState(null);
  const [floorAndLimits, setFloorAndLimits] = useState([]);

  let crops = [];
  let limit = [];
  let limitUtil = [];
  useEffect(() => {
    setSeason("Kharif 2022");
  }, []);

  // dashboard info
  useEffect(() => {
    let token = localStorage.getItem("token");

    if (season !== null) {
      if (season === "All Seasons") {
        fetch("/api/rmsCropLimitsAll", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setFloorAndLimits(data);
          });
        fetch("/api/rmsDashboardAll", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setTotalPolicies(data["total_policies"]);
            setTotalFPOS(data["total_fpos"]);
            setSumInsured(data["sum_insured"]);
            setTotalPayout(data["total_payout"]);
          });
      } else {
        fetch("/api/rmsDashboard", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            season: season,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setTotalPolicies(data["total_policies"]);
            setTotalFPOS(data["total_fpos"]);
            setSumInsured(data["sum_insured"]);
            setTotalPayout(data["total_payout"]);
          });
        fetch("/api/rmsFloorLimits", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            season: season,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setFloorAndLimits(data);
          });
      }
    }
  }, [season]);
  for (let i in floorAndLimits) {
    limit.push(floorAndLimits[i]["crop_limit"]);
  }
  for (let i in floorAndLimits) {
    crops.push(floorAndLimits[i]["crop"]);
  }
  for (let i in floorAndLimits) {
    limitUtil.push(floorAndLimits[i]["crop_limit_utilization"]);
  }
  let options = [
    { value: "All Seasons", label: "All Seasons" },
    { value: "Kharif 2022", label: "Kharif 2022" },
    { value: "Rabi 2022", label: "Rabi 2022" },
    { value: "Kharif 2021", label: "Kharif 2021" },
  ];
  const handleDownloadReport = async (e) => {
    let token = localStorage.getItem("token");

    fetch("/api/rmsReport", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Overview.xlsx";
        a.click();
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  return (
    <>
      <PolicyNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 93px)",
        }}
      >
        {/* Season Dropdown */}
        <div className="col-md-3">
          <div className="row justify-content-center mt-1">
            <div className="col-md-8" style={{ fontSize: "13px" }}>
              <Label style={{ fontWeight: "bold", fontSize: "16px" }}>
                Select Season
              </Label>
              <FormGroup>
                <Select
                  options={options}
                  value={{
                    value: season !== null ? season : "",
                    label: season !== null ? season : "",
                  }}
                  onChange={(obj) => setSeason(obj["value"])}
                  required
                />
              </FormGroup>
            </div>
          </div>
          {season !== "All Seasons" ? (
            <>
              <Card
                body
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  paddingLeft: "3%",
                  margin: "2px 0px 20px 10px",
                }}
              >
                <CardTitle tag="h5" className="lead">
                  Issued Floor Prices
                </CardTitle>
                <Jumbotron className="pt-3 pb-3 mb-0 text-left ml-2">
                  {floorAndLimits.length > 0 && season !== "All Seasons"
                    ? Object.entries(floorAndLimits).map(([key, value]) => (
                        <>
                          <p className="lead mb-0">
                            <FaLeaf className="mr-1" />
                            {value["crop"]}
                          </p>
                          <p className="text-muted small">
                            {value["market"]}
                            <br />
                            <BiRupee />
                            {value["floor_price"]}
                          </p>
                          <hr className="my-2" />
                        </>
                      ))
                    : ""}
                </Jumbotron>
              </Card>
            </>
          ) : (
            ""
          )}
          <Button
            style={{
              backgroundColor: "#4941A7",
              borderColor: "#4941A7",
            }}
            className="shadow"
            type="submit"
            onClick={handleDownloadReport}
          >
            Download Report
          </Button>
        </div>

        <div className="col-md-9">
          <div
            className="row"
            style={{
              backgroundColor: "#EFEFEF",
            }}
          >
            {/* No of Policies */}
            <div className="col-md-3">
              <Card
                body
                className="shadow"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  paddingLeft: "3%",
                  marginTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "10px",
                  color: "#4941A7",
                }}
              >
                <CardTitle tag="h6" className="lead">
                  Total No. of Policies
                </CardTitle>
                <CardSubtitle tag="h2" className="mb-2">
                  {totalPolicies ? totalPolicies : "0"}
                </CardSubtitle>
              </Card>
            </div>

            {/* Total FPOS */}
            <div className="col-md-3">
              <Card
                body
                className="shadow"
                style={{
                  backgroundColor: "#fff",
                  color: "#4941A7",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  paddingLeft: "3%",
                  marginTop: "10px",
                  borderRadius: "15px",
                  paddingBottom: "10px",
                }}
              >
                <CardTitle tag="h6" className="lead">
                  Total FPOs
                </CardTitle>
                <CardSubtitle tag="h2" className="mb-2">
                  {totalFPOS ? totalFPOS : "0"}
                </CardSubtitle>
              </Card>
            </div>

            {/* Total Sum Insured */}
            <div className="col-md-3">
              <Card
                body
                className="shadow"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  color: "#4941A7",
                  paddingLeft: "3%",
                  marginTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "10px",
                }}
              >
                <CardTitle tag="h6" className="lead">
                  Total Sum Insured
                </CardTitle>
                <CardSubtitle tag="h2" className="mb-2">
                  <FaRupeeSign size={25} />{" "}
                  {sumInsured ? sumInsured.toLocaleString("en-IN") : "0"}
                </CardSubtitle>
              </Card>
            </div>

            {/* Total Payout */}
            <div className="col-md-3">
              <Card
                body
                className="shadow"
                style={{
                  backgroundColor: "#fff",
                  color: "#4941A7",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  paddingLeft: "3%",
                  marginTop: "10px",
                  borderRadius: "15px",
                  paddingBottom: "10px",
                }}
              >
                <CardTitle tag="h6" className="lead">
                  Total Payout
                </CardTitle>
                <CardSubtitle tag="h2" className="mb-2">
                  <FaRupeeSign size={25} />{" "}
                  {totalPayout ? totalPayout.toLocaleString("en-IN") : "0"}
                </CardSubtitle>
              </Card>
            </div>
          </div>
          {/* <div className="row mt-1">
            <div className="col-md-2" style={{ fontSize: "13px" }}>
              <Label>Select Season</Label>
              <FormGroup>
                <Select
                  options={options}
                  value={{
                    value: season !== null ? season : "",
                    label: season !== null ? season : "",
                  }}
                  onChange={(obj) => setSeason(obj["value"])}
                  required
                />
              </FormGroup>
            </div>
          </div> */}
          <div
            className="row justify-content-center mt-5"
            style={{
              backgroundColor: "#EFEFEF",
            }}
          >
            <div className="col-md-12">
              <Card
                body
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                }}
              >
                <CropLimit
                  limit={limit}
                  limitUtil={limitUtil}
                  season={season}
                  crops={crops}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="col-md-12">
          <CardFooter
            style={{
              width: "100%",
              backgroundColor: "#4941A7",
              color: "#fff",
              fontSize: "15px",
              height: "45px",
              marginTop: "-10px",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-10">Powered by Agtuall</div>
            </div>
          </CardFooter>
        </div>
      </div>
    </>
  );
};
