import React, { useEffect, useState } from "react";
import { AppNavbar } from "./AppNavbar";
import {
  CardTitle,
  Card,
  CardFooter,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import {
  FaArrowDown,
  FaArrowUp,
  FaGlobeAsia,
  FaRegMoneyBillAlt,
} from "react-icons/fa";
import { MdLocalShipping } from "react-icons/md";

export const Overview = () => {
  const JsonTable = require("ts-react-json-table");

  const [crop, setCrop] = useState(null);
  useEffect(() => {
    setCrop("Maize");
  }, []);
  let [internationalDemandCategory, setInternationalDemandCategory] = useState(
    []
  );
  let [domesticDemandCategory, setDomesticDemandCategory] = useState([]);
  let [internationalSupplyCategory, setInternationalSupplyCategory] = useState(
    []
  );
  let [domesticSupplyCategory, setDomesticSupplyCategory] = useState([]);
  let [domesticCategory, setDomesticCategory] = useState([]);

  let [otherCategory, setOtherCategory] = useState([]);
  let [priceCategory, setPriceCategory] = useState([]);
  const [loadingOverview, setLoadingOverview] = useState(true);
  const [othersModal, setOthersModal] = useState(false);
  const othersToggle = () => setOthersModal(!othersModal);
  const closeBtn = (
    <button className="close" onClick={othersToggle}>
      &times;
    </button>
  );
  const [priceModal, setPriceModal] = useState(false);
  const priceToggle = () => setPriceModal(!priceModal);
  const closeBtn2 = (
    <button className="close" onClick={priceToggle}>
      &times;
    </button>
  );
  const [domesticModal, setDomesticModal] = useState(false);
  const domesticToggle = () => setDomesticModal(!domesticModal);
  const closeBtn3 = (
    <button className="close" onClick={domesticToggle}>
      &times;
    </button>
  );
  const options = [
    {
      label: "Groundnut",
      value: "Groundnut",
    },
    {
      label: "Maize",
      value: "Maize",
    },
    {
      label: "Paddy",
      value: "Paddy",
    },
    {
      label: "Soybean",
      value: "Soybean",
    },
  ];
  let cols_domestic = [
    {
      key: "Factors",
      label: "Factors",
    },
    {
      key: "Sub-factors",
      label: "Sub-factors",
    },
    {
      key: "Date of Updation",
      label: "Date of Updation",
    },
  ];
  useEffect(() => {
    if (crop !== null) {
      setLoadingOverview(false);
      let token = localStorage.getItem("token");

      fetch("/api/cropIndicators", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          crop: crop,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setInternationalDemandCategory(
            data.filter(
              (internationalDemandCategory) =>
                internationalDemandCategory["Category"] === "International" &&
                internationalDemandCategory["Factors"] === "Demand"
            )
          );
          setInternationalSupplyCategory(
            data.filter(
              (internationalSupplyCategory) =>
                internationalSupplyCategory["Category"] === "International" &&
                internationalSupplyCategory["Factors"] === "Supply"
            )
          );
          setDomesticCategory(
            data.filter(
              (domesticCategory) => domesticCategory["Category"] === "Domestic"
            )
          );
          setDomesticDemandCategory(
            data.filter(
              (domesticDemandCategory) =>
                domesticDemandCategory["Category"] === "Domestic" &&
                domesticDemandCategory["Factors"] === "Demand"
            )
          );
          setDomesticSupplyCategory(
            data.filter(
              (domesticSupplyCategory) =>
                domesticSupplyCategory["Category"] === "Domestic" &&
                domesticSupplyCategory["Factors"] === "Supply"
            )
          );
          setOtherCategory(
            data.filter(
              (otherCategory) => otherCategory["Category"] === "Other Factors"
            )
          );
          setPriceCategory(
            data.filter(
              (priceCategory) => priceCategory["Category"] === "Price"
            )
          );
        });
      setLoadingOverview(true);
    }
  }, [crop]);
  return (
    <>
      <AppNavbar />

      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            style={{
              backgroundColor: "#fff",
              margin: "20px 10px 20px 10px",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              paddingLeft: "3%",
            }}
          >
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <CardTitle
                  tag="h4"
                  style={{ color: "#4941A7" }}
                  className="text-center"
                >
                  Commodity Indicators{" "}
                </CardTitle>
              </div>
              <div className="col-md-2">
                <Select
                  className="mt-1"
                  placeholder="Select Crop"
                  options={options}
                  onChange={(obj) => setCrop(obj["value"])}
                  value={{
                    value: crop !== null ? crop : "",
                    label: crop !== null ? crop : "",
                  }}
                  menuPlacement="auto"
                />
              </div>
            </div>

            <hr />
            {loadingOverview ? (
              <>
                <div className="row mt-2 mb-4">
                  {/* Domestic */}
                  <div
                    className={crop === "Soybean" ? "col-md-9" : "col-md-10"}
                  >
                    <Card
                      className="shadow"
                      style={{
                        backgroundColor: "#ffe6e6",
                        borderColor: "#ffe6e6",
                      }}
                      onClick={domesticToggle}
                    >
                      <h5 className="mt-2">
                        <img
                          className="mr-2 mb-1"
                          src="domestic.jpg"
                          height="18"
                          width="22"
                          alt="domestic"
                        />
                        Domestic
                      </h5>
                      <div className="row justify-content-center">
                        {/* Supply */}
                        <div
                          className={crop === "Maize" ? "col-md-8" : "col-md-6"}
                        >
                          <Label
                            style={{
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                          >
                            <MdLocalShipping size={20} className="mr-1" />
                            {domesticSupplyCategory.length !== 0
                              ? domesticSupplyCategory[0]["Factors"]
                              : ""}
                          </Label>

                          {domesticSupplyCategory.length !== 0 ? (
                            domesticSupplyCategory[0]["Overall Indicator"] ===
                            "Increase" ? (
                              <FaArrowUp
                                size={17}
                                className="ml-1"
                                style={{ color: "#00b300" }}
                              />
                            ) : (
                              <FaArrowDown
                                size={17}
                                className="ml-1"
                                style={{ color: "#e60000" }}
                              />
                            )
                          ) : (
                            ""
                          )}
                          <hr className="my-2 ml-3 mr-3" />

                          <div className="row ml-1">
                            {domesticSupplyCategory.map((item, index) => (
                              <div
                                className={
                                  crop === "maize" ? "col-md-2" : "col-md-3"
                                }
                              >
                                <Label
                                  style={{
                                    color: "#8E94A7",
                                    fontSize: "11px",
                                  }}
                                >
                                  {domesticSupplyCategory.length !== 0
                                    ? domesticSupplyCategory[index][
                                        "Sub-factors"
                                      ]
                                    : ""}{" "}
                                </Label>

                                <br />
                                <div className="row justify-content-center">
                                  {domesticSupplyCategory.length !== 0
                                    ? domesticSupplyCategory[index]["2021-22"]
                                    : ""}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Demand */}
                        <div
                          className={crop === "Maize" ? "col-md-4" : "col-md-6"}
                        >
                          <Label
                            style={{
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                          >
                            <img
                              className="mr-2 mb-1"
                              src="demand.png"
                              height="20"
                              width="20"
                              alt="domestic"
                            />
                            {domesticDemandCategory.length !== 0
                              ? domesticDemandCategory[0]["Factors"]
                              : ""}
                          </Label>
                          {domesticDemandCategory.length !== 0 ? (
                            domesticDemandCategory[0]["Overall Indicator"] ===
                            "Increase" ? (
                              <FaArrowUp
                                size={17}
                                className="ml-1"
                                style={{ color: "#00b300" }}
                              />
                            ) : (
                              <FaArrowDown
                                size={17}
                                className="ml-1"
                                style={{ color: "#e60000" }}
                              />
                            )
                          ) : (
                            ""
                          )}{" "}
                          <hr className="my-2 ml-3 mr-3" />
                          <div className="row mb-3">
                            {domesticDemandCategory.map((item, index) => (
                              <div className="col-md-3">
                                <Label
                                  style={{
                                    color: "#8E94A7",
                                    fontSize: "11px",
                                  }}
                                >
                                  {domesticDemandCategory.length !== 0
                                    ? domesticDemandCategory[index][
                                        "Sub-factors"
                                      ]
                                    : ""}{" "}
                                </Label>

                                <br />
                                <div className="row justify-content-center">
                                  {domesticDemandCategory.length !== 0
                                    ? domesticDemandCategory[index]["2021-22"]
                                    : ""}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <Modal
                    isOpen={domesticModal}
                    toggle={domesticToggle}
                    size="lg"
                  >
                    <ModalHeader toggle={domesticToggle} close={closeBtn3}>
                      Date of Updation
                    </ModalHeader>
                    <ModalBody>
                      <JsonTable
                        rows={
                          domesticCategory.length !== null
                            ? domesticCategory
                            : "none"
                        }
                        columns={cols_domestic}
                        className="table table-striped table-responsive-sm small table-bordered"
                      />{" "}
                    </ModalBody>
                  </Modal>

                  {/* Others */}
                  <div className={crop === "Soybean" ? "col-md-3" : "col-md-2"}>
                    <Card
                      className="shadow"
                      style={{
                        backgroundColor: "#eee6ff",
                        borderColor: "#eee6ff",
                      }}
                      onClick={othersToggle}
                    >
                      <h5 className="mt-2">
                        Other Factors{" "}
                        {otherCategory.length !== 0 ? (
                          otherCategory[0]["Overall Indicator"] ===
                          "Increase" ? (
                            <FaArrowUp
                              size={17}
                              className="ml-1"
                              style={{ color: "#00b300" }}
                            />
                          ) : (
                            <FaArrowDown
                              size={17}
                              className="ml-1"
                              style={{ color: "#e60000" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </h5>
                      <hr className="my-2 ml-3 mr-3" />
                      <div className="row mb-3 justify-content-center">
                        {otherCategory.map((item, index) => (
                          <div className="col-md-4">
                            <Label
                              style={{ color: "#8E94A7", fontSize: "11px" }}
                            >
                              {otherCategory.length !== 0
                                ? otherCategory[index]["Sub-factors"]
                                : ""}{" "}
                            </Label>

                            <br />
                            <div className="row justify-content-center">
                              {otherCategory.length !== 0
                                ? otherCategory[index]["2021-22"]
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <Modal isOpen={othersModal} toggle={othersToggle} size="sm">
                    <ModalHeader toggle={othersToggle} close={closeBtn}>
                      Date of Updation
                    </ModalHeader>
                    <ModalBody>
                      {otherCategory.length !== 0
                        ? otherCategory[0]["Date of Updation"]
                        : "none"}
                    </ModalBody>
                  </Modal>
                </div>
                <div className="row mt-2 mb-3">
                  {/* Price */}
                  <div className="col-md-6">
                    <Card
                      className="shadow"
                      style={{
                        backgroundColor: "#e6f2ff",
                        borderColor: "#e6f2ff",
                      }}
                      onClick={priceToggle}
                    >
                      <h5 className="mt-2">
                        <FaRegMoneyBillAlt className="mb-1 mr-1" />
                        Price
                      </h5>
                      <div className="row justify-content-center">
                        <Label
                          style={{
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {priceCategory.length !== 0
                            ? priceCategory[0]["Factors"]
                            : ""}
                        </Label>
                        {priceCategory.length !== 0 ? (
                          priceCategory[0]["Overall Indicator"] ===
                          "Increase" ? (
                            <FaArrowUp
                              size={17}
                              className="ml-1 mt-1"
                              style={{ color: "#00b300" }}
                            />
                          ) : (
                            <FaArrowDown
                              size={17}
                              className="ml-1 mt-1"
                              style={{ color: "#e60000" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <hr className="my-2 ml-3 mr-3" />

                      <div className="row mb-3 justify-content-center">
                        {priceCategory.map((item, index) => (
                          <div className="col-md-3">
                            <Label
                              style={{ color: "#8E94A7", fontSize: "11px" }}
                            >
                              {priceCategory.length !== 0
                                ? priceCategory[index]["Sub-factors"]
                                : ""}{" "}
                            </Label>

                            <br />
                            <div className="row justify-content-center">
                              {priceCategory.length !== 0
                                ? priceCategory[index]["2021-22"]
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <Modal isOpen={priceModal} toggle={priceToggle} size="sm">
                    <ModalHeader toggle={priceToggle} close={closeBtn2}>
                      Date of Updation
                    </ModalHeader>
                    <ModalBody>
                      {priceCategory.length !== 0
                        ? priceCategory[0]["Date of Updation"]
                        : "none"}
                    </ModalBody>
                  </Modal>
                  {/* International */}
                  <div className="col-md-6">
                    <Card
                      className="shadow"
                      style={{
                        backgroundColor: "#ebfaeb",
                        borderColor: "#ebfaeb",
                      }}
                      onClick={othersToggle}
                    >
                      <h5 className="mt-2">
                        <FaGlobeAsia className="mb-1 mr-1" />
                        International
                      </h5>
                      <div className="row mb-3 justify-content-center">
                        {/* Supply */}
                        <div className="col-md-6">
                          <Label
                            style={{
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                          >
                            <MdLocalShipping size={20} className="mr-1" />

                            {internationalSupplyCategory.length !== 0
                              ? internationalSupplyCategory[0]["Factors"]
                              : ""}
                          </Label>
                          {internationalSupplyCategory.length !== 0 ? (
                            internationalSupplyCategory[0][
                              "Overall Indicator"
                            ] === "Increase" ? (
                              <FaArrowUp
                                size={17}
                                className="ml-1"
                                style={{ color: "#00b300" }}
                              />
                            ) : (
                              <FaArrowDown
                                size={17}
                                className="ml-1"
                                style={{ color: "#e60000" }}
                              />
                            )
                          ) : (
                            ""
                          )}
                          <hr className="my-2 ml-1" />
                          <div className="row ml-3">
                            {internationalSupplyCategory.map((item, index) => (
                              <div className="col-md-6">
                                <Label
                                  style={{
                                    color: "#8E94A7",
                                    fontSize: "11px",
                                  }}
                                >
                                  {internationalSupplyCategory.length !== 0
                                    ? internationalSupplyCategory[index][
                                        "Sub-factors"
                                      ]
                                    : ""}{" "}
                                </Label>

                                <br />
                                <div className="row justify-content-center">
                                  {internationalSupplyCategory.length !== 0
                                    ? internationalSupplyCategory[index][
                                        "2021-22"
                                      ]
                                    : ""}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Demand */}
                        <div className="col-md-6">
                          <Label
                            style={{
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                          >
                            <img
                              className="mr-2 mb-1"
                              src="demand.png"
                              height="20"
                              width="20"
                              alt="domestic"
                            />
                            {internationalDemandCategory.length !== 0
                              ? internationalDemandCategory[0]["Factors"]
                              : ""}
                          </Label>
                          {internationalDemandCategory.length !== 0 ? (
                            internationalDemandCategory[0][
                              "Overall Indicator"
                            ] === "Increase" ? (
                              <FaArrowUp
                                size={17}
                                className="ml-1"
                                style={{ color: "#00b300" }}
                              />
                            ) : (
                              <FaArrowDown
                                size={17}
                                className="ml-1"
                                style={{ color: "#e60000" }}
                              />
                            )
                          ) : (
                            ""
                          )}{" "}
                          <hr className="my-2 mr-3" />
                          <div className="row ml-3 justify-content-center">
                            {internationalDemandCategory.map((item, index) => (
                              <div
                                className={
                                  crop === "Maize" ? "col-md-4" : "col-md-6"
                                }
                              >
                                <Label
                                  style={{
                                    color: "#8E94A7",
                                    fontSize: "11px",
                                  }}
                                >
                                  {internationalDemandCategory.length !== 0
                                    ? internationalDemandCategory[index][
                                        "Sub-factors"
                                      ]
                                    : ""}{" "}
                                </Label>

                                <br />
                                <div className="row justify-content-center">
                                  {internationalDemandCategory.length !== 0
                                    ? internationalDemandCategory[index][
                                        "2021-22"
                                      ]
                                    : ""}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center mb-2">
                <Spinner color="dark" />
              </div>
            )}
          </Card>
        </div>
      </div>
      {/* Footer */}
      <div className="row" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
