import React, { useState } from "react";
import Select from "react-select";
import { AppNavbar } from "./AppNavbar";
import { Card, CardFooter, CardTitle, Button, Label } from "reactstrap";
import data from "../../data/data.json";
import MaterialTable from "material-table";

// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import { SeasonAnalysisGraph } from "./SeasonAnalysisGraph";
import { FloorGraph } from "./FloorGraph";
// import Auth from "@aws-amplify/auth";

export const SeasonAnalysis = () => {
  const [seasonAnalysis, setSeasonAnalysis] = useState([]);
  const [seasonAnalysisGraph, setSeasonAnalysisGraph] = useState([]);
  const [floorGraph, setFloorGraph] = useState([]);

  const [crop, setCrop] = useState(null);
  const [marketList, setMarketList] = useState([]);
  const [market, setMarket] = useState(null);

  let cols = [
    { field: "date", title: "Date" },
    { field: "month", title: "Month" },
    { field: "crop", title: "Crop" },
    { field: "state", title: "State" },
    { field: "market", title: "Market" },
    { field: "arrivals", title: "Arrivals(Q)" },
    { field: "final_price", title: "Spot Price" },
    { field: "mid_futures", title: "Futures" },
    { field: "futures_oi", title: "OI" },
    { field: "futures_vol", title: "Volume" },
  ];
  const handleConfirmButton = async (e) => {
    if (crop !== null && market !== null) {
      let token = localStorage.getItem("token");

      await fetch("/api/seasonAnalysisGraph", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "0") {
            setSeasonAnalysisGraph(data);
          }
        });

      await fetch("/api/seasonAnalysis", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: crop.commodity_name,
          market: market.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "0") {
            setSeasonAnalysis(data);
          }
        });
      if (
        crop.commodity_name === "Groundnut" ||
        crop.commodity_name === "Soybean" ||
        crop.commodity_name === "Paddy" ||
        crop.commodity_name === "Maize" ||
        crop.commodity_name === "Bengal Gram" ||
        crop.commodity_name === "Wheat"
      ) {
        await fetch("/api/floorGraph", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            commodity: crop.commodity_name,
            market: market.name,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setFloorGraph(data);
          });
      } else {
        store.addNotification({
          title: "Agtuall",
          message: "Cannot display floor graph. Select a Kharif crop",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select proper inputs",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  const handleCommodityChange = (obj) => {
    setCrop(obj);
    setMarketList(obj.markets);
    setMarket(null);
  };
  const handleMarketChange = (obj) => {
    setMarket(obj);
  };
  return (
    <>
      <AppNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12 col-12">
          <Card
            body
            className="shadow mt-3 ml-2 mr-2 mb-3 pb-0"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-3 mt-3">
                <CardTitle tag="h3">Season Analysis</CardTitle>
              </div>{" "}
              <div
                className="col-md-2 offset-md-4"
                style={{ fontSize: "13px" }}
              >
                <Label>Crop Name</Label>
                <Select
                  value={crop}
                  placeholder="Select Commodity"
                  options={data}
                  onChange={handleCommodityChange}
                  getOptionLabel={(x) => x.commodity_name}
                  getOptionValue={(x) => x.commodity_code}
                />
              </div>
              <div className="col-md-2" style={{ fontSize: "13px" }}>
                <Label>Market Name</Label>
                <Select
                  placeholder="Select Market Name"
                  value={market}
                  options={marketList}
                  onChange={handleMarketChange}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.code}
                />
              </div>
              <div className="col-md-1">
                <Button
                  className="pl-3 pr-3 shadow mt-3"
                  style={{
                    backgroundColor: "#4941A7",
                    borderColor: "#4941A7",
                  }}
                  onClick={handleConfirmButton}
                >
                  Confirm
                </Button>
              </div>
            </div>

            <div className="mt-3">
              {seasonAnalysisGraph.length > 0 ? (
                <>
                  <SeasonAnalysisGraph data={seasonAnalysisGraph} />
                </>
              ) : (
                ""
              )}
              {crop !== null && market !== null ? (
                crop.commodity_name === "Groundnut" ||
                crop.commodity_name === "Soybean" ||
                crop.commodity_name === "Paddy" ||
                crop.commodity_name === "Maize" ||
                crop.commodity_name === "Bengal Gram" ||
                crop.commodity_name === "Wheat" ? (
                  floorGraph.length !== 0 ? (
                    <>
                      <div className="mt-3 row">
                        <div className="col-md-12">
                          <FloorGraph data={floorGraph} />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {seasonAnalysis.length > 0 ? (
                <>
                  <div className="mt-3 mb-2">
                    <MaterialTable
                      data={seasonAnalysis}
                      columns={cols}
                      title="Prices, Arrivals, Futures Data"
                      options={{
                        toolbar: true,
                        paging: true,
                        pageSize: 5,
                        sorting: false,
                        exportButton: true,
                        exportAllData: true,
                        search: false,
                        padding: "dense",
                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: 12,
                          height: "12px",
                          zIndex: "auto",
                        },
                        rowStyle: {
                          fontSize: 13,
                        },
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </Card>
        </div>
      </div>
      {/* Footer */}
      <div className="row">
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
