import React, { useState, useEffect } from "react";
import { PortfolioNavbar } from "./PortfolioNavbar";
import { CardFooter, Card, CardTitle, Button, Label } from "reactstrap";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";
import Select from "react-select";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
// import Auth from "@aws-amplify/auth";

export const Reports = () => {
  const [performanceReport, setPerformanceReport] = useState([]);

  const [policyID, setPolicyID] = useState([]);
  const [policies, setPolicies] = useState(null);
  let x = [];
  const handlePolciyOptions = async () => {
    let token = localStorage.getItem("token");

    fetch("/api/policyDb", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPolicies(data);
      });
  };
  if (policies) {
    for (let i in policies) {
      x.push({
        label: policies[i]["rms_id"],
        value: policies[i]["rms_id"],
      });
    }
  }
  const handleDownloadButton = async (e) => {
    if (policyID !== null) {
      let token = localStorage.getItem("token");

      fetch("/api/mgmtReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          rms_id: policyID,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = policyID + ".xlsx";
          a.click();
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select the appropriate fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");

    fetch("/api/performance", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPerformanceReport(data);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }, []);
  let cols2 = [
    { field: "Crop", title: "Crop", defaultGroupOrder: 0 },
    { field: "Count Issuance", title: "Count Issuance", grouping: false },
    { field: "FPO Name", title: "FPO Name", grouping: false },
    { field: "Season", title: "Season", grouping: false },
    { field: "Crop Year", title: "Crop Year", grouping: false },
    { field: "RMS Value", title: "RMS Value", grouping: false },
    { field: "Service Fee", title: "Service Fee", grouping: false },
    {
      field: "Service Fee/RMS value",
      title: "Service Fee/RMS Value(%)",
      grouping: false,
    },
    { field: "Payout", title: "Payout", grouping: false },
    {
      field: "Payout Count",
      title: "Payout Count",
      grouping: false,
      cellStyle: {
        width: "10px",
      },
    },
    { field: "Payout/RMS value", title: "Payout/RMS value", grouping: false },

    {
      field: "No Payout Count",
      title: "No Payout Count",
      grouping: false,
    },
    {
      field: "Farmer Benefit from storing",
      title: "Farmer Benefit",
      grouping: false,
    },
    { field: "Benefit %", title: "Benefit %", grouping: false },

    { field: "Fee/Payout", title: "Fee/Payout", grouping: false },
    {
      field: "Issuance Date",
      title: "Issuance Date(yyyy-mm-dd)",
      grouping: false,
    },
    {
      field: "Closure Date",
      title: "Closure Date(yyyy-mm-dd)",
      grouping: false,
    },
  ];
  return (
    <>
      <PortfolioNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-3 mb-3 ml-3 mr-3 pb-0"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <CardTitle tag="h4">Performance Report</CardTitle>
            <hr />
            {performanceReport.length !== 0 ? (
              <>
                <div className="row justify-content-center mb-3 mt-0">
                  <div className="col-md-12">
                    <MaterialTable
                      data={performanceReport}
                      columns={cols2}
                      title="Performance Report"
                      options={{
                        toolbar: false,
                        showTitle: false,
                        paging: false,
                        sorting: false,
                        padding: "dense",
                        search: false,
                        grouping: true,
                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: 12,
                          height: "12px",
                          zIndex: "auto",
                        },
                        rowStyle: (rowData) => ({
                          backgroundColor:
                            typeof rowData["Count Issuance"] === "number"
                              ? "#80aaff"
                              : "#FFF",
                          fontSize: "12px",
                        }),
                      }}
                    />
                    <CSVLink
                      data={performanceReport}
                      filename={"Performance_Report.csv"}
                      className="justify-content-center mb-4"
                    >
                      <Button
                        className="pl-4 pr-4 shadow mt-3"
                        style={{
                          backgroundColor: "#4941A7",
                          borderColor: "#4941A7",
                        }}
                        type="submit"
                      >
                        Download
                      </Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </Card>
          {/* Mgmt Reporting */}
          <div className="row">
            <div className="col-md-12">
              <Card
                body
                className="shadow ml-3 mr-3 mb-3 pb-0"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  color: "#4941A7",
                }}
              >
                <CardTitle tag="h4">Management Reporting</CardTitle>
                <hr />
                <div className="row justify-content-center mb-4">
                  <div className="col-md-4">
                    <Label>RMS ID</Label>
                    <Select
                      options={x}
                      placeholder="Select RMS ID"
                      onChange={(obj) => setPolicyID(obj["value"])}
                      onFocus={handlePolciyOptions}
                      menuPlacement="top"
                    />
                  </div>
                  <div className="col-md-4">
                    <Button
                      className="pl-4 pr-4 shadow"
                      style={{
                        backgroundColor: "#4941A7",
                        borderColor: "#4941A7",
                        marginTop: "30px",
                      }}
                      onClick={handleDownloadButton}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="row">
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
