import React, { useState, useEffect } from "react";
import { CardFooter, Card, CardTitle, Label, Spinner } from "reactstrap";
import { PortfolioNavbar } from "./PortfolioNavbar";
import MaterialTable from "material-table";
import Select from "react-select";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

export const Simulation = () => {
  const [portfolioTable, setPortfolioTable] = useState([]);
  const [version, setVersion] = useState(null);

  const avgReturn = [];
  let portfolio_cols = [
    { field: "training_start", title: "Training Start" },
    { field: "training_end", title: "Training Till" },
    { field: "pred_year", title: "Pred Year" },
    { field: "subset_count", title: "Subset Count" },
    { field: "avg_loss", title: "Predicted Return(%)" },
    { field: "actual_loss", title: "Actual Return(%)" },
  ];
  let cols_premiums = [
    { field: "training_end", title: "Training Till" },
    { field: "pred_year", title: "Pred Year" },
    { field: "premium_chana", title: "Chana" },
    { field: "premium_groundnut", title: "Groundnut" },
    { field: "premium_maizeGulabbagh", title: "Maize(Gulabbagh)" },
    { field: "premium_maizeNizamabad", title: "Maize(Nizamabad)" },
    { field: "premium_paddyHardoi", title: "Paddy(Hardoi)" },
    { field: "premium_paddyItarsi", title: "Paddy(Itarsi)" },
    { field: "premium_soybean", title: "Soybean" },
    { field: "premium_tur", title: "Tur" },
    { field: "premium_wheat", title: "Wheat" },
  ];
  let cols_premiums_gnt = [
    { field: "training_end", title: "Training Till" },
    { field: "pred_year", title: "Pred Year" },
    { field: "premium_chana", title: "Chana" },
    { field: "premium_groundnut", title: "Groundnut" },
    { field: "premium_maizeGulabbagh", title: "Maize(Gulabbagh)" },
    { field: "premium_maizeNizamabad", title: "Maize(Nizamabad)" },
    { field: "premium_soybean", title: "Soybean" },
    { field: "premium_tur", title: "Tur" },
    { field: "premium_wheat", title: "Wheat" },
  ];
  let cols_weights = [
    { field: "training_end", title: "Training Till" },
    { field: "pred_year", title: "Pred Year" },
    { field: "weights_chana", title: "Chana" },
    { field: "weights_groundnut", title: "Groundnut" },
    { field: "weights_maizeGulabbagh", title: "Maize(Gulabbagh)" },
    { field: "weights_maizeNizamabad", title: "Maize(Nizamabad)" },
    { field: "weights_paddyHardoi", title: "Paddy(Hardoi)" },
    { field: "weights_paddyItarsi", title: "Paddy(Itarsi)" },
    { field: "weights_soybean", title: "Soybean" },
    { field: "weights_tur", title: "Tur" },
    { field: "weights_wheat", title: "Wheat" },
  ];
  let cols_weights_gnut = [
    { field: "training_end", title: "Training Till" },
    { field: "pred_year", title: "Pred Year" },
    { field: "weights_chana", title: "Chana" },
    { field: "weights_groundnut", title: "Groundnut" },
    { field: "weights_maizeGulabbagh", title: "Maize(Gulabbagh)" },
    { field: "weights_maizeNizamabad", title: "Maize(Nizamabad)" },
    { field: "weights_soybean", title: "Soybean" },
    { field: "weights_tur", title: "Tur" },
    { field: "weights_wheat", title: "Wheat" },
  ];
  let cols_future_loss = [
    { field: "pred_year", title: "Pred Year" },
    { field: "future_loss_chana", title: "Chana" },
    { field: "future_loss_groundnut", title: "Groundnut" },
    { field: "future_loss_maize_gulabbagh", title: "Maize(Gulabbagh)" },
    { field: "future_loss_maize_nizamabad", title: "Maize(Nizamabad)" },
    { field: "future_loss_paddy_hardoi", title: "Paddy(Hardoi)" },
    { field: "future_loss_paddy_itarsi", title: "Paddy(Itarsi)" },
    { field: "future_loss_soybean", title: "Soybean" },
    { field: "future_loss_tur", title: "Tur" },
    { field: "future_loss_wheat", title: "Wheat" },
  ];
  let cols_gnut = [
    { field: "pred_year", title: "Pred Year" },
    { field: "future_loss_chana", title: "Chana" },
    { field: "future_loss_groundnut", title: "Groundnut" },
    { field: "future_loss_maize_gulabbagh", title: "Maize(Gulabbagh)" },
    { field: "future_loss_maize_nizamabad", title: "Maize(Nizamabad)" },
    { field: "future_loss_soybean", title: "Soybean" },
    { field: "future_loss_tur", title: "Tur" },
    { field: "future_loss_wheat", title: "Wheat" },
  ];
  for (let i in portfolioTable) {
    avgReturn.push(portfolioTable[i]["actual_loss"]);
  }
  function getAvg(data) {
    data = data.filter((n) => n);
    const total = data.reduce((acc, c) => acc + c, 0);
    return total / data.length;
  }
  const version_options = [
    {
      label: "Version 1",
      value: "Version 1",
    },
    {
      label: "Version 2",
      value: "Version 2",
    },
    {
      label: "Version 3",
      value: "Version 3",
    },
    {
      label: "Version 4",
      value: "Version 4",
    },
  ];
  useEffect(() => {
    setVersion("Version 4");
  }, []);
  useEffect(() => {
    let token = localStorage.getItem("token");

    if (version === "Version 1") {
      fetch("/api/portfolioTableVersion1", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPortfolioTable(data);
        });
    } else if (version === "Version 2") {
      fetch("/api/portfolioTableVersion2", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPortfolioTable(data);
        });
    } else if (version === "Version 3") {
      fetch("/api/portfolioTableVersion3", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPortfolioTable(data);
        });
    } else if (version === "Version 4") {
      fetch("/api/portfolioTableVersion4", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPortfolioTable(data);
        });
    }
  }, [version]);
  return (
    <>
      <PortfolioNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-3 mb-3 pb-0 ml-3 mr-3"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <CardTitle tag="h4">Historical Portfolio Results</CardTitle>
                <hr />{" "}
                <div className="mb-3 row">
                  {portfolioTable.length !== 0 ? (
                    <>
                      <div className="col-md-12">
                        <MaterialTable
                          data={portfolioTable}
                          columns={portfolio_cols}
                          options={{
                            toolbar: false,
                            showTitle: false,
                            padding: "dense",
                            paging: false,
                            sorting: false,
                            grouping: false,
                            draggable: false,
                            headerStyle: {
                              backgroundColor: "#4941a7",
                              color: "#fff",
                              fontSize: 12,
                            },
                            rowStyle: {
                              fontSize: 12,
                            },
                          }}
                        />

                        <div className="row justify-content-end">
                          <div className="col-md-4 mt-2">
                            <Label
                              className="p-2 shadow"
                              style={{
                                backgroundColor: "#4941a7",
                                borderColor: "#4941a7",
                                color: "#fff",
                                borderRadius: "25px",
                                fontSize: "12px",
                              }}
                            >
                              Average Actual Return:
                              <b> {getAvg(avgReturn).toFixed(2)}</b>
                            </Label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-11">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="col-md-2 offset-md-1"
                style={{ fontSize: "13px" }}
              >
                <Label className="mb-0">Select Version</Label>

                <Select
                  className="mt-1"
                  placeholder="Select Version"
                  options={version_options}
                  onChange={(obj) => setVersion(obj["value"])}
                  value={{
                    value: version !== null ? version : "",
                    label: version !== null ? version : "",
                  }}
                  menuPlacement="auto"
                />
              </div>
            </div>
            {portfolioTable.length !== 0 ? (
              <>
                <div className="mt-3 mb-3">
                  <Accordion
                    preExpanded={["1"]}
                    allowZeroExpanded
                    allowMultipleExpanded
                  >
                    <AccordionItem uuid="1">
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ fontWeight: "bold" }}>
                          Premiums(%)
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MaterialTable
                          data={portfolioTable}
                          columns={
                            version === "Version 4"
                              ? cols_premiums_gnt
                              : cols_premiums
                          }
                          options={{
                            toolbar: false,
                            showTitle: false,
                            padding: "dense",
                            paging: false,
                            sorting: false,
                            grouping: false,
                            draggable: false,

                            headerStyle: {
                              backgroundColor: "#4941A7",
                              color: "#fff",
                              fontSize: 12,
                            },
                            rowStyle: {
                              fontSize: 12,
                            },
                          }}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="2">
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ fontWeight: "bold" }}>
                          Weights(Total out of 1)
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MaterialTable
                          data={portfolioTable}
                          columns={
                            version === "Version 4"
                              ? cols_weights_gnut
                              : cols_weights
                          }
                          options={{
                            toolbar: false,
                            showTitle: false,
                            padding: "dense",
                            paging: false,
                            sorting: false,
                            draggable: false,

                            grouping: false,
                            headerStyle: {
                              backgroundColor: "#4941A7",
                              color: "#fff",
                              fontSize: 12,
                            },
                            rowStyle: {
                              fontSize: 12,
                            },
                          }}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="3">
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ fontWeight: "bold" }}>
                          Actual Loss
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MaterialTable
                          data={portfolioTable}
                          columns={
                            version === "Version 4"
                              ? cols_gnut
                              : cols_future_loss
                          }
                          options={{
                            toolbar: false,
                            showTitle: false,
                            padding: "dense",
                            paging: false,
                            sorting: false,
                            grouping: false,
                            draggable: false,

                            headerStyle: {
                              backgroundColor: "#4941A7",
                              color: "#fff",
                              fontSize: 12,
                            },
                            rowStyle: {
                              fontSize: 12,
                            },
                          }}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </>
            ) : (
              ""
            )}
          </Card>
        </div>
      </div>

      {/* Footer */}
      <div className="row">
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
