import React, { useState, useEffect } from "react";
import { Card, CardFooter, Tooltip } from "reactstrap";
import { InitialNavbar } from "../InitialNavbar";
import { FaPhoneAlt } from "react-icons/fa";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
export const RMSInformation = () => {
  let [tooltipOpen, setTooltipOpen] = useState(false);
  let toggle = () => setTooltipOpen(!tooltipOpen);

  let [fpoDetails, setFPODetails] = useState([]);
  let [policyDetails, setPolicyDetails] = useState([]);
  let [selectedRowData, setSelectedRowData] = useState([]);
  let [lastPrices, setLastPrices] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("token");

    fetch("/api/getFPOInfo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== 0) {
          setFPODetails(data);
        } else {
          store.addNotification({
            title: "Agtuall",
            message: "No RMS present to display.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  }, []);
  const handleDownloadRow = async (rowData) => {
    let token = localStorage.getItem("token");

    let table_content = [];

    await fetch("/api/policyDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        rms_id: rowData["rms_id"],
        fpo_name: rowData["fpo_name"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        table_content.push(data[0]["issuance_quantity"]);
        table_content.push(data[0]["service_charge"]);
        table_content.push(data[0]["rms_value"]);
        table_content.push(data[0]["storage_receipt"]);
      });
    await fetch("/api/getMaxTenure", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData["crop"],
        state: rowData["state"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        table_content.push(data["lockin_period"]);
        table_content.push(data["max_tenure"]);
        table_content.push(data["max_settlement"]);
      });

    const doc = new jsPDF();

    doc.autoTable({
      theme: "grid",
      head: [["Heading", "Description"]],
      body: [
        ["Risk Management Service ID", rowData["rms_id"]],
        ["Storage Receipt No.", table_content[3]],
        ["FPO Name", rowData["fpo_name"]],
        ["Maximum limit for Risk Management Service", "Rs 30,00,000"],
        ["Issuance Quantity", table_content[0] + " quintals"],
        [
          "Risk Management Service Value",
          "Rs " + table_content[2].toLocaleString("en-IN"),
        ],
        ["Initiation Date of Risk Management Service", table_content[4]],
        ["End Date of Risk Management Service", table_content[5]],
        ["Evaluation Period", table_content[4] + " to " + table_content[5]],
        ["Floor Price", "Rs " + rowData["floor_price"]],
        ["Max Settlement Price", "Rs " + table_content[6]],

        ["Crop", rowData["crop"]],
        ["Reference price", "NCDEX " + rowData["market"] + " Spot Price"],
        [
          "Service Charge(without tax)",
          "Rs " + table_content[1].toLocaleString("en-IN"),
        ],
        ["Institution financing SR", "Aryadhan Financial Solutions"],
      ],
    });
    doc.save(rowData["rms_id"] + ".pdf");
  };

  const handleRowClick2 = async (rowData) => {
    let token = localStorage.getItem("token");

    let table_content = [];

    await fetch("/api/policyDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        rms_id: rowData["rms_id"],
        fpo_name: rowData["fpo_name"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPolicyDetails(data);
      });
    await fetch("/api/lastPrices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        crop: rowData["crop"],
        state: rowData["state"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        table_content.push(data[0]["date"]);
        table_content.push(data[0]["final_price"]);
        setLastPrices(data);
      });
  };

  const handlePriceData = async (rowData) => {
    const doc = new jsPDF();

    doc.autoTable({
      theme: "grid",
      columns: [
        { header: "Date", dataKey: "date" },
        { header: "Price", dataKey: "final_price" },
      ],
      body: lastPrices,
    });
    doc.save(rowData["crop"] + "_" + rowData["market"] + ".pdf");
  };
  return (
    <>
      <InitialNavbar />
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-2 ml-2 mr-2"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              borderRadius: "10px",
              color: "#4941A7",
            }}
          >
            <h5>RMS Information</h5>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              Select a RMS to know more
            </p>

            <div className="row">
              <div className="col-md-12 mb-5 mt-4 pl-0 pr-3">
                {fpoDetails.length > 0 ? (
                  <>
                    <MaterialTable
                      title=""
                      columns={[
                        { title: "FPO Name", field: "fpo_name" },
                        {
                          title: "RMS ID",
                          field: "rms_id",
                        },
                        { title: "Crop", field: "crop" },
                        { title: "Market", field: "market" },
                        { title: "RMS Start Date", field: "start_date" },
                        { title: "Floor Price", field: "floor_price" },
                        { title: "Status", field: "status", width: "15%" },
                        {
                          field: "status",
                          title: "Status",
                          render: (rowData) => {
                            if (rowData.status === "Live") {
                              return (
                                <span
                                  className="colored-circle"
                                  style={{
                                    backgroundColor: "#00FF00",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    marginBottom: "-2px",
                                    borderRadius: "100%",
                                    borderStyle: "solid",
                                    borderWidth: "0.5px",
                                    borderColor: "#00FF00",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              );
                            } else if (rowData.status === "Closed") {
                              return (
                                <span
                                  className="colored-circle"
                                  style={{
                                    backgroundColor: "#ffff00",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    marginBottom: "-2px",
                                    borderRadius: "100%",
                                    borderStyle: "solid",
                                    borderWidth: "0.5px",
                                    borderColor: "#ffff00",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              );
                            } else if (rowData.status === "Claim Ready") {
                              return (
                                <span
                                  className="colored-circle"
                                  style={{
                                    backgroundColor: "#ff0000",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    marginBottom: "-2px",
                                    borderRadius: "100%",
                                    borderStyle: "solid",
                                    borderWidth: "0.5px",
                                    borderColor: "#ff0000",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              );
                            }
                          },
                        },
                      ]}
                      data={fpoDetails ? fpoDetails : "none"}
                      actions={[
                        {
                          icon: "download",
                          tooltip: "Download RMS Details",
                          onClick: (event, rowData) => {
                            handleDownloadRow(rowData);
                          },
                        },
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        padding: "dense",
                        draggable: false,
                        sorting: false,
                        paging: false,
                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: "12px",
                        },
                        rowStyle: (rowData) => {
                          let selected =
                            selectedRowData &&
                            selectedRowData["rms_id"] === rowData["rms_id"];
                          return {
                            backgroundColor: selected ? "#4dff4d" : "#FFF",
                            color: selected ? "#000" : "#000",
                            fontSize: 12,
                          };
                        },
                      }}
                      onRowClick={(evt, rowData) => {
                        setSelectedRowData(rowData);
                        handleRowClick2(rowData);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="mt-5">
                  {policyDetails.length > 0 ? (
                    <>
                      <h6>Detailed RMS Information</h6>
                      <MaterialTable
                        title=""
                        columns={[
                          {
                            title: "RMS ID",
                            field: "rms_id",
                          },
                          { title: "Crop", field: "crop" },
                          { title: "State", field: "state" },

                          { title: "Market", field: "market" },
                          { title: "RMS Start Date", field: "start_date" },
                          { title: "RMS End Date", field: "end_date" },
                          { title: "RMS Value", field: "rms_value" },
                          { title: "Issuance Qty", field: "issuance_quantity" },
                          { title: "Payout", field: "payout" },
                          {
                            title: "Final SP",
                            field: "final_settlement_price",
                          },
                        ]}
                        data={policyDetails ? policyDetails : "none"}
                        actions={[
                          {
                            icon: "download",
                            tooltip: "Download Price Data",
                            onClick: (event, rowData) => {
                              handlePriceData(rowData);
                            },
                          },
                        ]}
                        options={{
                          padding: "dense",
                          draggable: false,
                          sorting: false,
                          paging: false,
                          actionsColumnIndex: -1,
                          search: false,
                          toolbar: false,
                          headerStyle: {
                            backgroundColor: "#4941A7",
                            color: "#fff",
                            fontSize: "12px",
                          },
                          rowStyle: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <SortTable /> */}
          </Card>
        </div>
      </div>
      {/* Footer` */}
      <CardFooter
        style={{
          width: "100%",
          backgroundColor: "#4941A7",
          color: "#fff",
          fontSize: "15px",
          height: "40px",
          marginTop: "-10px",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-md-6 offset-md-3">Powered by Agtuall</div>
          <div className="col-md-2 offset-md-1" style={{ fontSize: "14px" }}>
            <FaPhoneAlt
              style={{ color: "#fff" }}
              className="mr-2 mb-1"
              id="TooltipExample"
              onClick={() => navigator.clipboard.writeText("0120 4859303")}
            />
            0120 4859303
            <Tooltip
              placement="left"
              isOpen={tooltipOpen}
              target="TooltipExample"
              toggle={toggle}
            >
              Click to copy
            </Tooltip>
          </div>
        </div>
      </CardFooter>
    </>
  );
};
