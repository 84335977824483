import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Tooltip,
} from "reactstrap";
import Select from "react-select";
import { PolicyNavbar } from "./PolicyNavbar";
import { BiRupee } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";
import "../../styles.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import MaterialTable from "material-table";

// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
// import Auth from "@aws-amplify/auth";

export const NewPolicyOnboarding = (props) => {
  const [fpo_name, setFPOName] = useState(null);
  let [stateValue, setStateValue] = useState(null);
  let [marketValue, setMarketValue] = useState(null);
  let [cropValue, setCropValue] = useState(null);
  let [quantityValue, setQuantityValue] = useState(null);
  let [quantityLimit, setQuantityLimit] = useState(null);
  let [storageReceiptValue, setStorageReceiptValue] = useState(null);
  let [storageRows, setStorageRows] = useState([]);
  let [fpoId, setFPOId] = useState(null);
  let [testQty, setTestQty] = useState(null);
  let [servicePercentage, setServicePercentage] = useState(null);
  let [fpoNamesCustomerIds, setFPONamesCustomerIds] = useState([]);
  let [srVisible, setSRVisible] = useState(false);

  let [lastClosingPrice, setLastClosingPrice] = useState(null);
  let [lastClosingPriceDate, setLastClosingPriceDate] = useState(null);

  let [lockIn, setLockIn] = useState(null);
  let [endDate, setEndDate] = useState(null);

  let [tooltipOpen, setTooltipOpen] = useState(false);
  let toggle2 = () => setTooltipOpen(!tooltipOpen);

  const [floorPrice, setFloorPrice] = useState(null);
  // const [premiumPrice, setPremiumPrice] = useState(null);
  let [selectedRowData, setSelectedRowData] = useState(null);

  // modal useState
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // non-arya fpo modal
  const [modal2, setModal2] = useState(false);
  const nonAryaFPO = () => setModal2(!modal2);

  // start date
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;
  let today_modal = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    let token = localStorage.getItem("token");

    fetch("/api/getCustomerId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFPONamesCustomerIds(data);
      });
  }, []);
  useEffect(() => {
    let token = localStorage.getItem("token");

    if (cropValue !== null && stateValue !== null) {
      fetch("/api/getMaxTenure", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          crop: cropValue,
          state: stateValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["message"] !== "empty df") {
            setEndDate(data["max_tenure"]);
            setLockIn(data["lockin_period"]);
          } else {
            store.addNotification({
              title: "Agtuall",
              message:
                "Cannot populate end date since the crop is not present in Crop Master",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  }, [cropValue, stateValue]);

  let cols2 = [
    {
      field: "sr_no",
      title: "SR No.",
    },
    { field: "commodity", title: "Crop" },
    { field: "State", title: "State" },
    { field: "location", title: "Market" },
    { field: "transaction_qty_initial", title: "Initial Qty" },
    { field: "closing_balance_qty", title: "Closing Qty" },
  ];

  const handleFPONameChange = (obj) => {
    console.log(obj);
    setFPOName(obj);
    setSRVisible(true);
    setIsVisible(false);
  };
  const handleEndDate = (obj) => {
    setEndDate(obj.target.value);
  };
  useEffect(() => {
    if (fpo_name) {
      let token = localStorage.getItem("token");

      fetch("/api/getStorageReceiptTable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          fpo_name: fpo_name.fpo_name,
          lead_id: fpo_name.lead_id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setStorageRows(data);
        });
    }
  }, [fpo_name]);

  // Floor and Premium Price server call
  const handleFloorPrice2 = async (e) => {
    let token = localStorage.getItem("token");

    let dayOfWeek = new Date(endDate).getDay();
    let isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

    if (
      cropValue != null &&
      stateValue != null &&
      marketValue != null &&
      quantityValue != null &&
      endDate != null
    ) {
      if (
        parseInt(quantityValue) <= parseInt(quantityLimit) &&
        parseInt(quantityValue) !== 0
      ) {
        if (isWeekend === false) {
          await fetch("/api/getFloorPrice2", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              crop: cropValue,
              state: stateValue,
              market: marketValue,
              issuance_quantity: quantityValue,
              end_date: endDate,
              start_date: today_modal,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              setFloorPrice(data["floor_price"]);
              setLastClosingPrice(data["closing_price"]);
              setLastClosingPriceDate(data["last_closing_date"]);
            });
        } else {
          store.addNotification({
            title: "Agtuall",
            message: "Selected End Date cannot be a weekend.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      } else {
        store.addNotification({
          title: "Agtuall",
          message:
            "Coverage qty should be less than Closing qty.\n Check 'i' icon.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select proper inputs",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  // handle Add New RMS Button
  const handleAddButton = async (e, obj) => {
    let token = localStorage.getItem("token");

    if (
      cropValue != null &&
      marketValue != null &&
      quantityValue !== null &&
      floorPrice !== null
    ) {
      await fetch("/api/getCoverageQty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          crop: cropValue,
          state: stateValue,
          fpo_name: fpo_name ? fpo_name.fpo_name : fpo_name2,
          initial_qty: quantityValue,
          floor_price: floorPrice,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["message"] === "available crop limit crossed") {
            setTestQty(0);
          } else if (data["message"] === "available fpo limit crossed") {
            setTestQty(0);
          } else {
            setTestQty(data["issuance_quantity"]);
            setServicePercentage(data["service_percentage"]);
          }
        });
      setModal(true);
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please provide proper inputs",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const handleSelectedRow = async (rowData) => {
    setCropValue(rowData["commodity"]);
    setStateValue(rowData["State"]);
    setMarketValue(rowData["location"]);
    setQuantityLimit(rowData["closing_balance_qty"]);
    setStorageReceiptValue(rowData["sr_no"]);
    setFPOId(rowData["fpo_id"]);
    setQuantityValue(rowData["closing_balance_qty"]);
  };
  const handleSelectedRow2 = async (rowData) => {
    setCropValue(rowData["crop"]);
    setStateValue(rowData["state"]);
    setMarketValue(rowData["market"]);
    setQuantityValue(rowData["closing_quantity"]);
    setStorageReceiptValue(rowData["sr_no"]);
    setQuantityLimit(rowData["closing_quantity"]);
  };

  // after clicking confirm on modal
  const handleConfirmPolicyButton = async (e) => {
    let token = localStorage.getItem("token");

    if (
      fpo_name != null &&
      cropValue != null &&
      quantityValue != null &&
      endDate != null
    ) {
      await fetch("/api/postPolicyDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          fpo_name: fpo_name.fpo_name,
          fpo_id: fpoId,
          crop: cropValue,
          state: stateValue,
          market: marketValue,
          issuance_quantity: testQty,
          policy_start: today_modal,
          policy_end: endDate,
          storage_receipt: storageReceiptValue,
          floor_price: floorPrice,
          service_charge: (
            testQty *
            floorPrice *
            (servicePercentage / 100)
          ).toFixed(0),
          status: "Live",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["message"] === "success") {
            store.addNotification({
              title: "Agtuall",
              message: "New RMS Added",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            props.history.push("/allRMS");
          } else if (data["message"] === "available crop limit crossed") {
            setModal(false);
            store.addNotification({
              title: "Agtuall",
              message: "The Crop Limit was crossed",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          } else if (data["message"] === "available fpo limit crossed") {
            setModal(false);
            store.addNotification({
              title: "Agtuall",
              message: "The FPO Limit was crossed",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Agtuall",
              message: "A rms already exists",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select the appropriate fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  const handleNewFPO = async (e, obj) => {
    let token = localStorage.getItem("token");

    await fetch("/api/newFPO", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["message"] === "Added new fpos") {
          store.addNotification({
            title: "Agtuall",
            message: "New FPOs have been added",
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Agtuall",
            message: "Something went wrong!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };

  const [fpo_name2, setFPOName2] = useState(null);
  let [stateValue2, setStateValue2] = useState(null);
  let [marketValue2, setMarketValue2] = useState(null);
  let [cropValue2, setCropValue2] = useState(null);
  let [quantityValue2, setQuantityValue2] = useState(null);
  let [nonAryaFPOSR, setNonAryaFPOSR] = useState([]);
  let [isVisible, setIsVisible] = useState(false);

  const handleConfirmNonAryaFPO = async (e) => {
    let token = localStorage.getItem("token");

    if (
      fpo_name2 !== null &&
      cropValue2 !== null &&
      quantityValue2 !== null &&
      stateValue2 !== null &&
      marketValue2 !== null &&
      quantityValue2 > 0
    ) {
      if (
        cropValue2 === "Bengal Gram" ||
        cropValue2 === "Wheat" ||
        cropValue2 === "Maize" ||
        cropValue2 === "Groundnut" ||
        cropValue2 === "Soybean" ||
        cropValue2 === "Soyabean" ||
        cropValue2 === "Paddy" ||
        cropValue2 === "Tur"
      ) {
        if (
          stateValue2 === "Maharashtra" ||
          stateValue2 === "Bihar" ||
          stateValue2 === "Madhya Pradesh" ||
          stateValue2 === "Uttar Pradesh" ||
          stateValue2 === "Telangana" ||
          stateValue2 === "Karnataka" ||
          stateValue2 === "Rajasthan"
        ) {
          await fetch("/api/postNonAryaFPO", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              fpo_name: fpo_name2,
              crop: cropValue2,
              state: stateValue2,
              market: marketValue2,
              closing_quantity: quantityValue2,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data !== "error") {
                setNonAryaFPOSR(data);
                nonAryaFPO(false);
                setSRVisible(false);
                setFPOName(null);
                setIsVisible(true);
              } else {
                store.addNotification({
                  title: "Agtuall",
                  message: "Duplicate SR/ Non Arya FPO",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true,
                  },
                });
              }
            });
        } else {
          store.addNotification({
            title: "Agtuall",
            message: "Please enter correct state name",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      } else {
        store.addNotification({
          title: "Agtuall",
          message: "Please enter correct crop name",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please enter the appropriate fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  let non_arya_sr_cols = [
    {
      field: "sr_no",
      title: "SR No.",
    },
    { field: "crop", title: "Crop" },
    { field: "state", title: "State" },
    { field: "market", title: "Market" },
    { field: "initial_quantity", title: "Initial Qty" },
    { field: "closing_quantity", title: "Closing Qty" },
  ];
  const handleConfirmPolicyButton2 = async (e) => {
    let token = localStorage.getItem("token");

    if (
      fpo_name2 != null &&
      cropValue2 != null &&
      quantityValue2 != null &&
      endDate != null
    ) {
      await fetch("/api/postPolicyDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          fpo_name: fpo_name2,
          fpo_id: 0,
          crop: cropValue2,
          state: stateValue2,
          market: marketValue2,
          issuance_quantity: testQty,
          policy_start: today_modal,
          policy_end: endDate,
          storage_receipt: storageReceiptValue,
          floor_price: floorPrice,
          service_charge: (
            testQty *
            floorPrice *
            (servicePercentage / 100)
          ).toFixed(0),
          status: "Live",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["message"] === "success") {
            store.addNotification({
              title: "Agtuall",
              message: "New RMS Added",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            props.history.push("/allRMS");
          } else if (data["message"] === "available crop limit crossed") {
            setModal(false);
            store.addNotification({
              title: "Agtuall",
              message: "The Crop Limit was crossed",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          } else if (data["message"] === "available fpo limit crossed") {
            setModal(false);
            store.addNotification({
              title: "Agtuall",
              message: "The FPO Limit was crossed",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Agtuall",
              message: "A rms already exists",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please select the appropriate fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  return (
    <>
      <PolicyNavbar />

      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="shadow mt-3 ml-3 mr-3 mb-3 pb-0"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              borderRadius: "10px",
              paddingBottom: "0px",
              color: "#4941A7",
            }}
          >
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <CardTitle style={{ fontSize: "25px", fontWeight: "bold" }}>
                  New RMS Entry
                </CardTitle>
              </div>
              <div className="col-md-1">
                <Button
                  className="shadow-lg"
                  style={{
                    backgroundColor: "#4941A7",
                    borderColor: "#4941A7",
                    float: "right",
                    fontSize: "12px",
                  }}
                  onClick={handleNewFPO}
                >
                  Add FPO
                </Button>
              </div>
              <div className="col-md-1">
                <Button
                  className="shadow-lg"
                  style={{
                    backgroundColor: "#4941A7",
                    borderColor: "#4941A7",
                    float: "right",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setModal2(true);
                  }}
                >
                  Manual SR
                </Button>
              </div>
            </div>

            <hr className="my-1" />
            <div className="row justify-content-center">
              {/* FPO Name Input */}
              <div className="col-md-6">
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Step 1: FPO Selection
                </p>
                <Select
                  className="ml-5 mr-5 mb-3"
                  placeholder="FPO Name"
                  value={fpo_name ? fpo_name : "none"}
                  options={fpoNamesCustomerIds}
                  onChange={handleFPONameChange}
                  getOptionLabel={(x) => x.fpo_name}
                  getOptionValue={(x) => x.lead_id}
                  //dev
                  // options={demo_fpo_options}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              {/* Storage Receipt Tables */}
              <div className="col-md-10">
                {srVisible && storageRows.length !== 0 ? (
                  <div>
                    {" "}
                    <MaterialTable
                      title=""
                      columns={cols2}
                      data={storageRows}
                      options={{
                        showTitle: false,
                        toolbar: false,
                        search: false,
                        paging: true,
                        draggable: false,
                        padding: "dense",
                        sorting: false,
                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: 12,
                          height: "12px",
                          zIndex: "auto",
                        },
                        rowStyle: (rowData) => {
                          let selected =
                            selectedRowData &&
                            selectedRowData["sr_no"] === rowData["sr_no"];
                          return {
                            backgroundColor: selected ? "#4dff4d" : "#FFF",
                            color: selected ? "#000" : "#000",
                            fontSize: 12,
                          };
                        },
                      }}
                      onRowClick={(evt, rowData) => {
                        setSelectedRowData(rowData);
                        handleSelectedRow(rowData);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {isVisible && nonAryaFPOSR.length !== 0 ? (
                  <>
                    <MaterialTable
                      title=""
                      columns={non_arya_sr_cols}
                      data={nonAryaFPOSR}
                      options={{
                        showTitle: false,
                        toolbar: false,
                        search: false,
                        paging: false,
                        draggable: false,
                        padding: "dense",
                        sorting: false,
                        headerStyle: {
                          backgroundColor: "#4941A7",
                          color: "#fff",
                          fontSize: 12,
                          height: "12px",
                          zIndex: "auto",
                        },
                        rowStyle: (rowData) => {
                          let selected =
                            selectedRowData &&
                            selectedRowData["sr_no"] === rowData["sr_no"];
                          return {
                            backgroundColor: selected ? "#4dff4d" : "#FFF",
                            color: selected ? "#000" : "#000",
                            fontSize: 12,
                          };
                        },
                      }}
                      onRowClick={(evt, rowData) => {
                        setSelectedRowData(rowData);
                        handleSelectedRow2(rowData);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Card>

          {/* Row 2 */}
          <div className="row mt-2">
            <div className="col-md-12">
              <Card
                body
                className="shadow mt-3 ml-3 mr-3 mb-3 pb-0"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  color: "#4941A7",
                }}
              >
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Step 2: Inputs
                </p>
                <hr className="my-1" />
                <div className="row mt-2 justify-content-center">
                  {/* State Input */}
                  <div className="col-md-2">
                    <Label for="exampleDate">State</Label>

                    <Input
                      value={stateValue ? stateValue : "Enter State"}
                      name="state"
                      placeholder="Enter State"
                    />
                  </div>

                  {/* Market Input */}
                  <div className="col-md-2">
                    <Label for="exampleDate">Market Name</Label>

                    <Input
                      value={marketValue ? marketValue : "Enter Market"}
                      name="market"
                      placeholder="Enter Market"
                    />
                  </div>

                  {/* Crop Name input */}
                  <div className="col-md-2">
                    <Label for="exampleDate">Crop Name</Label>

                    <Input
                      value={cropValue ? cropValue : "Enter Crop"}
                      name="crop"
                      placeholder="Enter Crop"
                    />
                  </div>
                  {/* End Date INput */}
                  <div className="col-md-2">
                    <Label for="exampleDate">End Date</Label>

                    <Input
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                      value={endDate}
                      onChange={handleEndDate}
                    />
                  </div>
                  <div className="col-md-2">
                    <Label for="exampleDate">
                      Coverage Quantity(q)
                      <AiOutlineInfoCircle
                        id="TooltipExample"
                        size={17}
                        style={{ color: "#012646" }}
                        className="ml-1"
                      />
                      <Tooltip
                        placement="right"
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggle2}
                      >
                        Less than {quantityLimit ? quantityLimit : "none"}
                      </Tooltip>
                    </Label>

                    <Input
                      placeholder="Enter Coverage Quantity"
                      name="qty"
                      type="number"
                      min="0"
                      max="100"
                      maxLength="2"
                      value={quantityValue}
                      onChange={(e) => {
                        setQuantityValue(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* Calc Now Button */}
                <div className="row justify-content-center">
                  <Button
                    className="pl-4 pr-4 shadow-lg mb-4 mt-4"
                    style={{
                      backgroundColor: "#4941A7",
                      borderColor: "#4941A7",
                    }}
                    onClick={handleFloorPrice2}
                  >
                    Calculate Now
                  </Button>
                </div>
              </Card>
            </div>
          </div>

          {/* Row 3 */}
          <div className="row mt-2">
            <div className="col-md-12">
              <Card
                body
                className="shadow mt-3 ml-3 mr-3 mb-3 pb-0"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  color: "#4941A7",
                }}
              >
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Step 3: Outputs
                </p>
                <hr className="my-1" />
                <div className="row mt-3 justify-content-center">
                  {/* Yesterday's Price */}
                  <div
                    className="col-md-4"
                    style={{ backgroundColor: "mistyrose" }}
                  >
                    {/* Label */}
                    <Label
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      Last NCDEX Price (
                      {lastClosingPriceDate ? lastClosingPriceDate : "none"}):
                    </Label>
                    {/* Value */}

                    <Label
                      className="mt-3 pr-4 pl-4 pt-1 pb-1 ml-2"
                      style={{
                        backgroundColor: "#2A274F",
                        color: "#fff",
                        borderRadius: "25px",
                        fontSize: "13px",
                      }}
                    >
                      <BiRupee size={17} className="mb-1" />{" "}
                      {lastClosingPrice ? lastClosingPrice : "none"}
                    </Label>
                  </div>
                  {/* Floor Price */}
                  <div
                    className="col-md-4"
                    style={{ backgroundColor: "whitesmoke" }}
                  >
                    {/* Floor Price Label */}
                    <Label
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      Tentative Floor Price/Qunital:
                    </Label>
                    {/* FloorPrice Value */}

                    <Label
                      className="mt-3 pr-4 pl-4 pt-1 pb-1 ml-2"
                      style={{
                        backgroundColor: "#2A274F",
                        color: "#fff",
                        borderRadius: "25px",
                        fontSize: "13px",
                      }}
                    >
                      {floorPrice ? floorPrice : "none"}
                    </Label>
                  </div>

                  {/* RMS Coverage Period */}
                  <div
                    className="col-md-4"
                    style={{ backgroundColor: "mistyrose" }}
                  >
                    <Label
                      className="mr-auto"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      RMS Coverage Period:
                    </Label>

                    {/* Premium Price Value */}
                    <Label
                      className="mt-3 pr-4 pl-4 pt-1 pb-1 ml-2 mb-3"
                      style={{
                        backgroundColor: "#2A274F",
                        color: "#fff",
                        borderRadius: "25px",
                        fontSize: "13px",
                      }}
                    >
                      {lockIn && endDate ? lockIn + " to " + endDate : "none"}
                    </Label>
                  </div>
                </div>

                {/* Add Button */}
                <div className="row justify-content-center">
                  <Button
                    className="pl-4 pr-4 shadow-lg mt-3"
                    onClick={handleAddButton}
                    style={{
                      backgroundColor: "#4941A7",
                      borderColor: "#4941A7",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    Issue RMS
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Modal that opens after succesfull server response */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader style={{ color: "#000033" }}>
          RMS Confimation <br />
          <div className="small">
            <HiOutlineClock size={17} color="green" className="mb-1 mr-1" />
            {today_modal}
            <br />
            <HiOutlineClock size={17} color="red" className="mb-1 mr-1" />
            {endDate ? endDate : "none"}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              {/* FPO Name */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#0D0562",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                {fpo_name ? "FPO Name: " + fpo_name.fpo_name : fpo_name2}
                {/* dev */}
                {/* {fpo_name ? "FPO Name: " + fpo_name.value : "none"} */}
              </Label>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {" "}
              {/* Commodity Name */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                {cropValue ? cropValue : "none"}
              </Label>
              {/* State */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                {stateValue ? stateValue : "none"}
              </Label>
              {/* Market */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                {marketValue ? marketValue : "none"}
              </Label>
            </div>
            <div className="col-md-6">
              {" "}
              {/* Coverqty Quantity */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                {testQty ? testQty + " quintals" : "none quintals"}
              </Label>
              {/* Floor Price */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                Floor: <BiRupee size={20} />
                {floorPrice ? floorPrice.toLocaleString("en-IN") : "none"}
              </Label>
              {/* Premium Price */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                Service Fee: <BiRupee size={20} />
                {testQty !== null && servicePercentage !== null
                  ? (testQty * floorPrice * (servicePercentage / 100)).toFixed(
                      0
                    )
                  : 0}
              </Label>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* RMS Value */}
              <Label
                className="pt-2 pl-4 pr-4 pb-2"
                style={{
                  backgroundColor: "#4941A7",
                  opacity: "90%",
                  color: "#fff",
                  borderRadius: "20px",
                }}
              >
                RMS Value: <BiRupee size={20} />
                {floorPrice
                  ? parseInt(floorPrice * testQty).toLocaleString("en-IN")
                  : "none"}
              </Label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {testQty !== 0 ? (
            <>
              {" "}
              <Button
                onClick={
                  fpo_name2
                    ? handleConfirmPolicyButton2
                    : handleConfirmPolicyButton
                }
                className="shadow-lg"
                style={{
                  backgroundColor: "#000099",
                  borderColor: "#003399",
                }}
              >
                Confirm
              </Button>{" "}
              <Button
                onClick={toggle}
                className="shadow"
                style={{
                  backgroundColor: "#cc0000",
                  borderColor: "#e60000",
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={toggle}
                className="shadow"
                style={{
                  backgroundColor: "#cc0000",
                  borderColor: "#e60000",
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      {/* Modal for adding a non arya fpo */}
      <Modal isOpen={modal2} toggle={nonAryaFPO}>
        <ModalHeader style={{ color: "#000033" }}>
          Manual SR Entry <br />
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              {/* FPO Name */}
              <Label style={{ fontSize: "13px" }}>FPO Name</Label>
              <Input
                type="text"
                required
                placeholder="FPO Name"
                onChange={(obj) => {
                  setFPOName2(obj.target.value);
                }}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6">
              <Label style={{ fontSize: "13px" }}>Crop</Label>
              <Input
                type="text"
                placeholder="Crop eg: Soybean"
                onChange={(obj) => {
                  setCropValue2(obj.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <Label style={{ fontSize: "13px" }}>State </Label>
              <Input
                type="text"
                placeholder="State eg: Madhya Pradesh"
                onChange={(obj) => {
                  setStateValue2(obj.target.value);
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <Label style={{ fontSize: "13px" }}>Market</Label>
              <Input
                type="text"
                placeholder="Market eg: Indore"
                onChange={(obj) => {
                  setMarketValue2(obj.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <Label style={{ fontSize: "13px" }}>Quantity (q)</Label>
              <Input
                type="number"
                placeholder="Coverage Qty eg: 30"
                onChange={(obj) => {
                  setQuantityValue2(obj.target.value);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Button
            onClick={handleConfirmNonAryaFPO}
            className="shadow-lg"
            style={{
              backgroundColor: "#000099",
              borderColor: "#003399",
            }}
          >
            Confirm
          </Button>{" "}
          <Button
            onClick={nonAryaFPO}
            className="shadow"
            style={{
              backgroundColor: "#cc0000",
              borderColor: "#e60000",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Footer */}
      <div className="row">
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
