import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardTitle,
  CardFooter,
  CardSubtitle,
  Spinner,
} from "reactstrap";
//styling for start and end date fields
import "../../styles.css";

import Select from "react-select";
import data from "../../data/data.json";
import { HighCharts } from "./HighCharts";
import { Monthly } from "./Monthly";
import { ArrivalModalGraph } from "./ArrivalModalGraph";
import { AppNavbar } from "./AppNavbar";
import { CSVLink } from "react-csv";
// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

export const TryButton = (props) => {
  const JsonTable = require("ts-react-json-table");

  let curr_year = new Date().getFullYear();

  const [tableData, setTableData] = useState([]);
  const [commodity, setCommodity] = useState(null);
  const [market, setMarket] = useState(null);
  const [marketList, setMarketList] = useState([]);
  const [g, setg] = useState([]);
  const [source, setSource] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [tableArrival, setTableArrival] = useState([]);
  const [arrivalData, setArrivalData] = useState([]);
  const [getSowingAvgData, setSowingAvgData] = useState([]);
  const [loadingPhasesTable, setLoadingPhasesTable] = useState(true);

  let cols_modal_vs_arrivals = [
    { key: "month", label: "Month" },
    { key: "final_price", label: "Average Price (INR)" },
    { key: "arrivals", label: "Arrivals (q)" },
  ];
  let cols_monthly_average = [
    { key: "month", label: "Month" },
    { key: (curr_year - 5).toString(), label: (curr_year - 5).toString() },
    { key: (curr_year - 4).toString(), label: (curr_year - 4).toString() },
    { key: (curr_year - 3).toString(), label: (curr_year - 3).toString() },
    { key: (curr_year - 2).toString(), label: (curr_year - 2).toString() },
    { key: (curr_year - 1).toString(), label: (curr_year - 1).toString() },
  ];
  let cols_sowing = [
    { key: "", label: "#" },
    { key: "Year-2012", label: "2012" },
    { key: "Year-2013", label: "2013" },
    { key: "Year-2014", label: "2014" },
    { key: "Year-2015", label: "2015" },
    { key: "Year-2016", label: "2016" },
    { key: "Year-2017", label: "2017" },
    { key: "Year-2018", label: "2018" },
    { key: "Year-2019", label: "2019" },
    { key: "Year-2020", label: "2020" },
    { key: "Year-2021", label: "2021" },
    { key: "Average", label: "Average" },
  ];

  useEffect(() => {
    fetch("/api/defaultPriceTrend", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => setg(data));
  }, []);

  //to handle the commodity change in the dropdown
  const handleCommodityChange = (obj) => {
    setCommodity(obj);
    setMarketList(obj.markets);
    setMarket(null);
  };

  //handle the markets depending on the commodity chosen
  const handleMarketChange = (obj) => {
    setMarket(obj);
  };

  // handle the plot graph   // PRICE TRENDS GRAPH
  // also sets the monthly avg tabel
  const handleSubmit = async (e, obj) => {
    let token = localStorage.getItem("token");
    if (commodity != null && market != null) {
      if (
        commodity.commodity_name === "Bengal Gram" ||
        commodity.commodity_name === "Maize" ||
        commodity.commodity_name === "Mustard" ||
        (commodity.commodity_name === "Paddy" && market.name === "Kota") ||
        commodity.commodity_name === "Soybean" ||
        commodity.commodity_name === "Turmeric" ||
        commodity.commodity_name === "Wheat"
      ) {
        setSource("NCDEX");
      } else if (
        commodity.commodity_name === "Groundnut" ||
        (commodity.commodity_name === "Paddy" &&
          (market.name === "Hardoi" || market.name === "Itarsi"))
      ) {
        setSource("Agmarknet");
      }
      setLoadingPhasesTable(false);
      // sendData handles plotting a graph for a comm, market, start and end date (daily prices)
      await fetch("/api/sendData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => setg(data));

      // monthly is responsible for setting the monthly avg price graph for 5 years
      await fetch("/api/monthly", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => setMonthly(data));

      // getTable fetches the Monthly Average Prices for Last 5 Years Table
      await fetch("/api/getTable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => setTableData(data));
      // setLoadingMonthlyTable(true);

      // arrival is responsible for arrivals vs price graph
      await fetch("/api/arrival", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "No arrivals") {
            setTableArrival(data);
          } else {
            setTableArrival([]);
          }
        });

      // arrivalTable is responsible for arrivals vs price table
      await fetch("/api/arrivalTable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "No arrivals") {
            setArrivalData(data);
            // setLoadingArrivalTable(true);
          } else {
            setArrivalData([]);
          }
        });

      // Average Prices in Sowing, Arrival and Post-Arrival Phases for nine years
      await fetch("/api/cropSowingAvg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          commodity: commodity.commodity_name,
          market: market,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setSowingAvgData(data);
        });
      setLoadingPhasesTable(true);
    } else {
      store.addNotification({
        title: "Agtuall",
        message: "Please Select Crop and Market",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <>
      <AppNavbar />
      {/* the first row contains the comm, market and dates selection */}
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#EFEFEF",
        }}
      >
        {/* COMMODITY */}
        <div className="col-md-3" style={{ padding: "10px 50px 10px 50px" }}>
          <Select
            placeholder="Select Commodity"
            value={commodity}
            options={data}
            onChange={handleCommodityChange}
            getOptionLabel={(x) => x.commodity_name}
            getOptionValue={(x) => x.commodity_code}
          />
        </div>

        {/* MARKET */}
        <div className="col-md-3" style={{ padding: "10px 50px 10px 50px" }}>
          <Select
            placeholder="Select Market"
            value={market}
            options={marketList}
            onChange={handleMarketChange}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.code}
          />
        </div>

        {/* PLOT GRAPH BUTTON */}
        <div className="col-md-3">
          <Button
            className="mt-2 pl-4 pr-4"
            style={{ backgroundColor: "#4941A7" }}
            type="submit"
            onClick={handleSubmit}
          >
            Click to Get All
          </Button>
        </div>
      </div>

      {/* The second row consists of the graph(input related) */}
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
        }}
      >
        {/* CHART NO1 PRICE TRENDS */}
        <div className="col-md-12">
          <Card
            className="ml-2 mr-2"
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
            }}
          >
            <CardTitle tag="h5" style={{ color: "#4941A7" }}>
              Price Trend {source !== null ? "(Source: " + source + ")" : ""}
            </CardTitle>
            <div className="row justify-content-center">
              <HighCharts data={g} />
            </div>
            <div className="row justify-content-center">
              <CSVLink
                style={{
                  marginLeft: "40%",
                  marginRight: "40%",
                }}
                filename="PriceTrend.csv"
                data={g}
              >
                <Button
                  style={{
                    backgroundColor: "#4941A7",
                    marginBottom: "3%",
                  }}
                  type="submit"
                >
                  Download
                </Button>
              </CSVLink>{" "}
            </div>
          </Card>
        </div>
      </div>

      {/* the third row: averages of various phases for a commodity and market   */}
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            className="mt-3 ml-2 mr-2"
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              paddingLeft: "3%",
            }}
          >
            <CardTitle tag="h5" style={{ color: "#4941A7" }}>
              Average Prices in Sowing, Arrival and Post-Arrival Phases (Crop
              Year)
            </CardTitle>
            <hr />

            {loadingPhasesTable ? (
              <>
                <JsonTable
                  theadClassName={"thead-light"}
                  className="table table-sm table-bordered small table-responsive-sm"
                  rows={getSowingAvgData}
                  columns={cols_sowing}
                />
                <CSVLink
                  style={{
                    marginLeft: "40%",
                    marginRight: "40%",
                  }}
                  filename="AverageAcrossAllPhases.csv"
                  data={getSowingAvgData}
                >
                  <Button
                    style={{
                      backgroundColor: "#4941A7",
                      marginBottom: "3%",
                    }}
                    type="submit"
                  >
                    Download
                  </Button>
                </CSVLink>
              </>
            ) : (
              <div className="text-center mb-2">
                <Spinner color="dark" />
              </div>
            )}
          </Card>
        </div>
      </div>

      {/* the fourth row: arrivals vs prices and monthly avg table  */}
      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
        }}
      >
        {/* Modal price vs arrivals TABLE CALC */}
        <div className="col-md-6">
          <Card
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              paddingLeft: "3%",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <CardTitle tag="h5" style={{ color: "#4941A7" }}>
              Monthly Average(Price & Arrivals over {curr_year - 5}-
              {curr_year - 1})
            </CardTitle>
            {tableArrival.length > 0 ? (
              <ArrivalModalGraph data={tableArrival} />
            ) : (
              ""
            )}

            {arrivalData.length > 0 ? (
              <>
                <JsonTable
                  className="table table-striped small"
                  rows={arrivalData}
                  columns={cols_modal_vs_arrivals}
                />
                <CardSubtitle tag="h6" className="mb-2 text-muted">
                  Refers to Calendar Year
                </CardSubtitle>
              </>
            ) : (
              <h6>Data not Available</h6>
            )}
          </Card>
        </div>

        {/* MONTHLY AVERAGE PRICE TABLE */}
        <div className="col-md-6">
          <Card
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              paddingLeft: "3%",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <CardTitle tag="h5" style={{ color: "#4941A7" }}>
              Price Seasonals for Last 5 Years
            </CardTitle>
            {monthly ? (
              <>
                <Monthly data={monthly} tableData={tableData} />
              </>
            ) : (
              ""
            )}

            {tableData.length > 0 ? (
              <>
                <JsonTable
                  className="table table-striped small"
                  rows={tableData}
                  columns={cols_monthly_average}
                />
                <CardSubtitle tag="h6" className="mb-2 text-muted">
                  Refers to Calendar Year
                </CardSubtitle>
              </>
            ) : (
              <h6>Data not Available</h6>
            )}
          </Card>
        </div>
      </div>

      {/* FOOTER */}
      <div className="row" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="col-md-8"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
