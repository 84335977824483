import React, { useState } from "react";
import {
  Button,
  Card,
  CardTitle,
  CardFooter,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Spinner,
  Collapse,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { CSVLink } from "react-csv";
import { PortfolioNavbar } from "./PortfolioNavbar";
// import Auth from "@aws-amplify/auth";

export const About = () => {
  const JsonTable = require("ts-react-json-table");
  let [getAllMandiData, setAllMandiData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [q, setQ] = useState("");

  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleAllMandis = async (e, obj) => {
    let token = localStorage.getItem("token");

      setLoading(false);
      await fetch("/api/test", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => setAllMandiData(data));
      setLoading(true);

  };
  function search(rows) {
    return rows.filter(
      (row) =>
        row["Crop Name"].toLowerCase().indexOf(q) > -1 ||
        row["Market Name"].toLowerCase().indexOf(q) > -1
    );
  }

  let summary_cols = [
    { key: "Crop Name", label: "Crop Name" },
    { key: "Market Name", label: "Market Name" },
    {
      key: "Col3: Count of Years (Avg Price PA > Avg Price A) (2012-21)",
      label: "Col1: Count of Years (Avg Price PA > Avg Price A) (2012-21)",
    },
    {
      key: "Count of Years",
      label: "Year Count(for Col 1)",
    },
    {
      key: "Col4: Avg of Price increases (2012-21) in Col1",
      label: "Col2: Avg of Price increases (2012-21) in Col1",
    },
    {
      key: "Col5: 9-Yearly Avg of count of days when 10-day MA(PA) > 10% (A)",
      label:
        "Col3: 9-Yearly Avg of count of days when 10-day MA(PA) > 10% Avg(A)",
    },
    {
      key: "Col6: 9-yearly Avg of total count of datapoints in PA",
      label: "Col4: 9-yearly Avg of total count of datapoints in PA",
    },
    {
      key: "Score",
      label: "Score",
      cell: function (item, columnKey) {
        if (item["Score"] >= 1.5) {
          return (
            <td
              style={{
                backgroundColor: "#00b300",
                alignContent: "center",
                color: "#fff",
              }}
            >
              {item.Score}
            </td>
          );
        } else {
          return (
            <td
              style={{
                backgroundColor: "#e60000",
                alignContent: "center",
                color: "#fff",
              }}
            >
              {item.Score}
            </td>
          );
        }
      },
    },
  ];
  let method_data = [
    {
      Metric:
        "Count of years of Post-Arrival Phase average  price >Arrival Phase average price",
      Calculation: "If count is 5 out of 10 years, score is 5/10=50%",
      Weight: "0.5 Max score 1",
    },
    {
      Metric: "Average % price increases across the years for Col 1",
      Calculation:
        "if mandi x is 14% and the mandi with the biggest gain is  18%, score is 14/18=77.7%",
      Weight: "0.77 Max score 1",
    },
    {
      Metric:
        "Count of days when  10-SMA in Post-Arrival Phase higher than  Arrival Phase average price by 10% / Count of total days in Post-Arrival phase",
      Calculation:
        "Across all years, if the  total of days in the post-arrival phase is 1300 and the count of cases greater than 10% is 650, score is 650/1300=50%",
      Weight: "0.5 Max score 1",
    },
    {
      Metric: "Score",
      Calculation: "Sum of above three",
      Weight: "0.5+0.77+0.5=1.77",
    },
  ];
  var createReactClass = require("create-react-class");

  var SelectTable = createReactClass({
    getInitialState: function () {
      // We will store the selected cell and row, also the sorted column
      return { row: false, cell: false, sort: false };
    },
    render: function () {
      let me = this,
        // clone the rows
        items = this.props.rows.slice();
      // Sort the table
      if (this.state.sort) {
        items.sort(function (a, b) {
          return a[me.state.sort] > b[me.state.sort] ? 1 : -1;
        });
      }

      return (
        <JsonTable
          rows={search(getAllMandiData)}
          settings={this.getSettings()}
          onClickHeader={this.onClickHeader}
          // onClickRow={this.onClickRow}
          columns={summary_cols}
          theadClassName={"thead-dark"}
          className="table table-sm table-bordered table-responsive-sm small"
        />
      );
    },

    getSettings: function () {
      let me = this;
      // We will add some classes to the selected rows and cells
      return {
        keyField: "name",
        headerClass: function (current, key) {
          // key is the col name in the columns array, current is jsonColumn_colname
          // eslint-disable-next-line
          if (me.state.sort === key) {
            return current + " headerSelected";
          }
          return current;
        },
        rowClass: function (current, item) {
          if (me.state.row === item.name) return current + " rowSelected";
          return current;
        },
      };
    },
    onClickHeader: function (e, column) {
      this.setState({ sort: column });
    },
  });

  return (
    <>
      <PortfolioNavbar />

      <div
        className="row"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="col-md-12">
          <Card
            body
            style={{
              backgroundColor: "#fff",
              margin: "20px 10px 20px 10px",
              borderColor: "#f5f5f5",
              paddingTop: "10px",
              paddingLeft: "3%",
            }}
          >
            <CardTitle
              tag="h4"
              style={{ color: "#4941A7" }}
              className="text-center"
            >
              Analysis of Farmer Benefit from Storage
            </CardTitle>
            <hr />
            <div className="row justify-content-between">
              <div className="col-md-3">
                <Button
                  style={{
                    backgroundColor: "#4941A7",
                    borderColor: "#4941A7",
                  }}
                  className="shadow"
                  type="submit"
                  onClick={handleAllMandis}
                >
                  Run Test
                </Button>
              </div>
            </div>

            {/* get the summary table for all crops and markets */}

            {loading ? (
              getAllMandiData.length > 0 ? (
                <>
                  <div className="row justify-content-end">
                    <div className="col-md-3">
                      <FormGroup className="ml-auto mt-0">
                        <InputGroup>
                          <Input
                            className="mt-0"
                            type="text"
                            value={q}
                            placeholder="Search by Crop, Market"
                            onChange={(e) => setQ(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <BiSearchAlt2 />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                  <SelectTable rows={getAllMandiData} />
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <CSVLink
                        data={getAllMandiData}
                        className="justify-content-center"
                        filename={"Crop_Eligibility.csv"}
                      >
                        <Button
                          style={{
                            backgroundColor: "#4941A7",
                            borderColor: "#4941A7",
                          }}
                          className="shadow mb-3"
                          type="submit"
                        >
                          Download
                        </Button>
                      </CSVLink>
                    </div>
                  </div>

                  <Button
                    onClick={toggle}
                    className="shadow"
                    style={{
                      marginBottom: "1rem",
                      backgroundColor: "#f5f5f5",
                      color: "#000",
                      borderColor: "#f5f5f5",
                    }}
                  >
                    Read about Methodology
                    <IoIosArrowDown className="ml-2" />
                  </Button>
                  <Collapse isOpen={isOpen}>
                    <Card className="shadow">
                      <CardBody>
                        <JsonTable
                          rows={method_data}
                          className="table table-sm table-bordered table-responsive-sm small"
                        />
                        <CardSubtitle tag="h6">
                          The threshold for eligible crop market combinations is
                          kept as 1.5
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                  </Collapse>
                </>
              ) : (
                ""
              )
            ) : (
              <div className="text-center mb-2">
                <Spinner color="dark" />
              </div>
            )}
          </Card>
        </div>
      </div>

      {/* Footer */}
      <div className="row" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="col-md-12"></div>
        <CardFooter
          style={{
            width: "100%",
            backgroundColor: "#4941A7",
            color: "#fff",
            fontSize: "15px",
            height: "40px",
            marginTop: "-10px",
          }}
        >
          Powered by Agtuall
        </CardFooter>
      </div>
    </>
  );
};
