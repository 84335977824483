import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardFooter,
  CardImg,
  CardSubtitle,
  Tooltip,
} from "reactstrap";
import "../mainLanding.css";
import { InitialNavbar } from "./InitialNavbar";
// react notification component
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import { FaPhoneAlt } from "react-icons/fa";

export const MainLanding = (props) => {
  const [userRole, setUserRole] = useState(null);

  let [tooltipOpen, setTooltipOpen] = useState(false);
  let toggle = () => setTooltipOpen(!tooltipOpen);
  const mapObj = (f) => (obj) =>
    Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
  const toArrayOfStrings = (value) => [`${value}`];
  const mapToArrayOfStrings = mapObj(toArrayOfStrings);

  // yuo can find all params from here
  useEffect(() => {
    let token = new URLSearchParams(props.location.search).get("token");
    fetch("/api/isValidToken", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) window.location.href = "https://arya.ag/?returnUrl=rms.arya.ag/";
        else return response.json();
      })
      .then((data) => setUserRole(data["user"].split("/")[1]));
  }, [props]);
  
  return (
    <>
      {userRole === "fpo" ? (
        <>
          <InitialNavbar />

          <div
            className="row justify-content-center"
            style={{
              backgroundColor: "#EFEFEF",
              minHeight: "calc(100vh - 90px)",
            }}
          >
            {/* Go to commodity analysis */}
            <div className="col-md-4 mt-3">
              <Card
                body
                className="card-pops"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  borderRadius: "20px",
                  paddingBottom: "50px",
                  marginLeft: "10px",
                }}
                onClick={() => props.history.push("/dashboard")}
              >
                <CardImg
                  style={{
                    borderRadius: "40%",
                    marginBottom: "40px",
                    marginTop: "30px",
                    maxHeight: "300px",
                    height: "300px",
                    display: "block",
                    objectFit: "cover",
                  }}
                  src="stock.jpg"
                  alt="Card image cap"
                />
                <CardTitle tag="h4">Commodity Analysis</CardTitle>
                <CardSubtitle>
                  Price & Market Analysis, Crop Market Eligibility
                </CardSubtitle>
              </Card>
            </div>

            {/* Go to RMS Information */}
            <div className="col-md-4 mt-3">
              <Card
                body
                className="card-pops"
                onClick={() => props.history.push("/rmsInformation")}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  borderRadius: "20px",
                  paddingBottom: "50px",
                  marginLeft: "10px",
                  marginBottom: "20px",
                }}
              >
                <CardImg
                  style={{
                    borderRadius: "40%",
                    marginBottom: "40px",
                    marginTop: "30px",
                    maxHeight: "300px",
                    height: "300px",
                    display: "block",
                    objectFit: "cover",
                  }}
                  src="bg_image2.jpg"
                  alt="Card image cap"
                />
                <CardTitle tag="h4">RMS Information</CardTitle>
                <CardSubtitle>
                  Get all your rms information at one place.
                </CardSubtitle>
              </Card>
            </div>
          </div>
          {/* Footer` */}
          <CardFooter
            style={{
              width: "100%",
              backgroundColor: "#4941A7",
              color: "#fff",
              fontSize: "15px",
              height: "40px",
              marginTop: "-10px",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-6 offset-md-3">Powered by Agtuall</div>
              <div
                className="col-md-2 offset-md-1"
                style={{ fontSize: "14px" }}
              >
                <FaPhoneAlt
                  style={{ color: "#fff" }}
                  className="mr-2 mb-1"
                  id="TooltipExample"
                  onClick={() => navigator.clipboard.writeText("0120 4859303")}
                />
                0120 4859303
                <Tooltip
                  placement="left"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Click to copy
                </Tooltip>
              </div>
            </div>
          </CardFooter>
        </>
      ) : userRole === "sales" || userRole === "admin" ? (
        <>
          <InitialNavbar />
          <div
            className="row"
            style={{
              backgroundColor: "#EFEFEF",
              minHeight: "calc(100vh - 90px)",
            }}
          >
            {/* Go to commodity analysis */}
            <div className="col-md-4 mt-3">
              <Card
                body
                className="card-pops"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  borderRadius: "20px",
                  paddingBottom: "50px",
                  marginLeft: "10px",
                }}
                onClick={() => props.history.push("/dashboard")}
              >
                <CardImg
                  style={{
                    borderRadius: "40%",
                    marginBottom: "40px",
                    marginTop: "30px",
                    maxHeight: "300px",
                    height: "300px",
                    display: "block",
                    objectFit: "cover",
                  }}
                  src="stock.jpg"
                  alt="Card image cap"
                  onClick={() => props.history.push("/rmsDashboard")}
                />
                <CardTitle tag="h4">Commodity Analysis</CardTitle>
                <CardSubtitle>
                  Price & Market Analysis, Crop Market Eligibility
                </CardSubtitle>
              </Card>
            </div>

            {/* Go to RMS Mgmt */}
            <div className="col-md-4 mt-3">
              <Card
                body
                className="card-pops"
                onClick={() => props.history.push("/rmsDashboard")}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  borderRadius: "20px",
                  paddingBottom: "50px",
                  marginLeft: "10px",
                  marginBottom: "20px",
                }}
              >
                <CardImg
                  style={{
                    borderRadius: "40%",
                    marginBottom: "40px",
                    marginTop: "30px",
                    maxHeight: "300px",
                    height: "300px",
                    display: "block",
                    objectFit: "cover",
                  }}
                  // height="300px"
                  src="bg_image2.jpg"
                  alt="Card image cap"
                />
                <CardTitle tag="h4">RMS Management</CardTitle>
                <CardSubtitle>
                  Create, monitor rms for all crops at one place.
                </CardSubtitle>
              </Card>
            </div>

            {/* Go to portfolio mgmt */}
            <div className="col-md-4 mt-3">
              <Card
                body
                className="card-pops"
                onClick={() => {
                  if (userRole === "sales") {
                    props.history.push("/");
                    store.addNotification({
                      title: "Agtuall",
                      message: "Oops! You do not have access to this module",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });
                  } else {
                    props.history.push("/portfolioDashboard");
                  }
                }}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  borderRadius: "20px",
                  paddingBottom: "50px",
                  marginLeft: "10px",
                }}
              >
                <CardImg
                  style={{
                    borderRadius: "40%",
                    marginBottom: "40px",
                    marginTop: "30px",
                    maxHeight: "300px",
                    height: "300px",
                    display: "block",
                    objectFit: "cover",
                  }}
                  src="login.jpg"
                  alt="Card image cap"
                />
                <CardTitle tag="h4">Portfolio Management</CardTitle>
                <CardSubtitle>Portfolio Insights.</CardSubtitle>
              </Card>
            </div>
          </div>
          {/* Footer` */}
          <CardFooter
            style={{
              width: "100%",
              backgroundColor: "#4941A7",
              color: "#fff",
              fontSize: "15px",
              height: "40px",
              marginTop: "-10px",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-6 offset-md-3">Powered by Agtuall</div>
              <div
                className="col-md-2 offset-md-1"
                style={{ fontSize: "14px" }}
              >
                <FaPhoneAlt
                  style={{ color: "#fff" }}
                  className="mr-2 mb-1"
                  id="TooltipExample"
                  onClick={() => navigator.clipboard.writeText("0120 4859303")}
                />
                0120 4859303
                <Tooltip
                  placement="left"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Click to copy
                </Tooltip>
              </div>
            </div>
          </CardFooter>
        </>
      ) : (
        <h2 className="text-center mt-3">Redirecting...</h2>
      )}
    </>
  );
};
